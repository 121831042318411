function dateConverter(ISODate) {
  const date = new Date(ISODate);
  
  // Formata a data e hora para "dd/mm/aaaa hh:mm"
  const dia = String(date.getDate())
  const mes = String(date.getMonth() + 1)
  
  const formatado = `${dia}/${mes}`
  return formatado
  
}

export default dateConverter