import { useState, useEffect } from 'react'
import { useAuth } from '../../../AuthContext'
import { useNavigate } from "react-router-dom"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'

import SearchConcurrent from './SearchConcurrent'
import Search from './Search'
import ConcurrentAnnsList from './ConcurrentAnnsList'
import fetch_items from './fetch_items'
import fetch_seller_details from './fetch_seller_details'
import search_for_title from './search_for_title'

import HasNotIntegration from '../HasNotIntegration'

function PageConcurrent () {

	const navigate = useNavigate()
	const { token } = useAuth()

	const [seller, setSeller] = useState()
	const [items, setItems] = useState([])
	
	const [sellerLoading, setSellerLoading] = useState(false)
	const [loading, setLoading] = useState(false)
	
	const [errorSellerMessage, setSellerErrorMessage] = useState(null)
	const [errorMessage, setErrorMessage] = useState(null)

	const [searchSeller, setSearchSeller] = useState('')
	const [initialSearchSeller, setInitialSearchSeller] = useState('')

	const [search, setSearch] = useState('')
	const [initialSearch, setInitialSearch] = useState('')
	
	// Realiza a busca pelos detalhes do vendedor
	async function fetch_seller (search) {
		setSellerErrorMessage(false)
		setSellerLoading(true)

		const response_seller = search !== ''
		  ? await fetch_seller_details(search)
			: null

		if (!response_seller.error) setSeller(response_seller)
		else setSellerErrorMessage(response_seller.error)
		
		setSellerLoading(false)
	}

	// Realiza a busca pelos anúncios do vendedor
	async function fetch_search (search) {
		setErrorMessage(false)
		setLoading(true)

		const response = search !== ''
			? await fetch_items(search, token)
			: []
		
		if (!response.error) setItems(response)
		else setErrorMessage(response.error)
		
		setLoading(false)
	}

	// Ao carregar a pagina
	useEffect(() => {
		// Gets URL Search Params
		const searchParams = new URLSearchParams(window.location.search)
		
		// Gets seller param
		const seller_url_query = searchParams.get('seller')
		if (seller_url_query) {
			setSearchSeller(seller_url_query)
			setInitialSearchSeller(seller_url_query)
		}
		
		// Gets search param
		const search_url_query = searchParams.get('search')
		if (search_url_query) {
			setSearch(search_url_query)
			setInitialSearch(search_url_query)
		}
	}, [])

	// Caso uma busca por vendedor seja definida
	useEffect(() => {
		if (searchSeller) {
			fetch_seller(searchSeller)
			fetch_search(searchSeller)
		
			const queryParams = new URLSearchParams(window.location.search)
			queryParams.set('seller', searchSeller)
			navigate(`?${queryParams.toString()}`)
		}
	}, [searchSeller])

	// Caso uma busca por anuncios seja definida
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
			
		if (searchSeller)
			queryParams.set('seller', searchSeller)
		
		if (search)
			queryParams.set('search', search)
		else
			queryParams.delete('search')

		navigate(`?${queryParams.toString()}`)
	}, [search])

	// Filters items
	const searched_items = []
	// Filtra os itens ja buscados, e mostra somente os que passarem no teste
	items.forEach(item => search_for_title(search, item) && searched_items.push(item) )

	return <Layout>
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={

		    	<Grid container spacing={2} style={{padding:'20px 0'}}>
	      	
		      	<Grid lg={12}>
							<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Concorrente
			      	</Typography>

							<Typography
			      		variant="p"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Busque pelo nome do vendedor no Mercado Livre para ver mais detalhes da conta e dos anúncios dele.
			      	</Typography>
			      </Grid>

						{/* Searches seller */}
			      <Grid lg={3}>
							<SearchConcurrent
								handleSearch={setSearchSeller}
								initialSearch={initialSearchSeller}
							/>
			      </Grid>

						{!errorSellerMessage && <Grid lg={12}>
							{sellerLoading
								? <p>Carregando detalhes do vendedor...</p>
								: seller && <p>
									{seller.name}: {seller.items} anúncios cadastrados, {seller.transactions} transações realizadas.
								</p>
							}
			      </Grid>}
						
						{/* Searches item on seller items, if seller is defined */}
						<Grid lg={3}>
							{searchSeller && <Search
								handleSearch={setSearch}
								initialSearch={initialSearch}
							/>}
			      </Grid>

						{errorMessage && <Grid lg={12}><p>{errorMessage}</p></Grid>}

			      {!errorMessage && <Grid lg={12}>
							{loading
								? <p>Carregando anúncios do vendedor... Isso pode demorar um pouco.</p>
								: <ConcurrentAnnsList items={searched_items} />
							}
			      </Grid>}
				  </Grid>

				}/>

		  </Box>
		</Container>
	</Layout>

}

export default PageConcurrent