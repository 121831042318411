import axios from '../../../../backend-config'

export default async function fetch_items(token){
	try{
			
		const response = await axios.get('mercadolivre/items_v2/quantity', {
			headers: { Authorization: token }
		})

		const data = response.data

		if (data.items && !data.items.error) {
			return data.items
		}
		console.log(data)
		
		if (data.items.error && data.items.error === 'error_getting_seller_items') {
			return { error: "Não foi possível carregar os anúncios. Verifique sua integração."}
		}
		
		if (data.items.error && data.items.error === 'internal_error') {
			return { error: "Houve um problema com as informações do mercado livre. Por favor, tente novamente."}
		}

	} catch (error) {
		
		return { error: "Houve um erro inesperado e não foi possível carregar os aúnncios."}
		
	}
}