import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import Layout from '../../../layout/panel/Layout'
import AnnsTable from './AnnsTable'

import HasNotIntegration from '../HasNotIntegration'

export default function PageAnns_Quantity () {

	return <Layout>
		
		<Container>
			<Box sx={{ flexGrow: 1 }}>
		    
				<HasNotIntegration children={
						
					<Grid container spacing={2} style={{ paddingTop: '20px' }}>
						<AnnsTable />
					</Grid>

				} />

		  	</Box>
		</Container>
	</Layout>

}