import Grid from '@mui/material/Unstable_Grid2'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help'
import { useAuth } from '../../../../../AuthContext'
import axios from '../../../../../backend-config'
import SlackLogo from './SlackLogo'

function SlackIntegrationForm ({hasIntegration}) {
    
    const { token } = useAuth()

    const slack_auth = async () => {
		const response = await axios.get(`slack/auth`, {
			headers: { Authorization: token }
		})
		const data = response.data
		if(data.error) alert(data.error)
        else {
            // console.log(data)
            window.location.href = data.url
        }
	}
    
    return <Grid xs={12} sx={{py:4}} >

        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            
            <Box sx={{ display: 'flex', marginRight: '20px', flexWrap: 'wrap', alignContent: 'center' }} ><SlackLogo width={120} /></Box>

            <Box sx={{ display: 'flex',flexDirection: 'column',flexGrow:1, }}>
                
                {!hasIntegration && <>
                    <Box>
                        <p style={{marginTop:0, marginBottom:0}}>
                            <strong>Sua conta ainda não está integrada ao Slack.</strong>
                        </p>
                        <p style={{marginTop:0}}>
                            Clicando no botão abaixo, você será redirecionado para a página de permissão do Slack.
                        </p>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                    }}>
                        <Button
                            variant="contained"
                            onClick={slack_auth}
                        >
                            Integrar ao Slack
                        </Button>
                        <Tooltip
                            title="Clicando em INTEGRAR AO SLACK, você autorizará a Lyzard a enviar mensagens para os seus canais do Slack."
                            arrow
                        >
                            <HelpIcon
                                sx={{
                                    color:'#ccc',
                                    marginLeft: '10px',
                                    marginTop: '5px'
                                }}
                            />
                        </Tooltip>
                    </Box>
                </>}

                {hasIntegration && <>
                    <Box>
                        <p style={{marginTop:0, marginBottom:0}}>
                            <strong>Sua conta está integrada ao Slack.</strong>
                        </p>
                        <p style={{marginTop:0}}>
                            Clicando no botão abaixo, você desfaz a integração ao Slack.
                        </p>
                    </Box>
                    
                    <Box sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignContent: 'center',
                    }}>
                        <Button
                            variant="contained"
                            onClick={() => alert('Esta função ainda não está ativa. Consulte o desenvolvedor.')}
                            color='error'
                        >
                            Cancelar Integração
                        </Button>
                        <Tooltip
                            title="Clicando em CANCELAR INTEGRAÇÃO, você não receberá mais notificações em seus canais do Slack."
                            arrow
                        >
                            <HelpIcon
                                sx={{
                                    color:'#ccc',
                                    marginLeft: '10px',
                                    marginTop: '5px'
                                }}
                            />
                        </Tooltip>
                    </Box>
                </>}
            </Box>
        </Box>
    </Grid>

}

export default SlackIntegrationForm