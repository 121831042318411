import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'

export default function PageNotFound (props) {

	return <Layout>
		<Container maxWidth="lg">
			<Box sx={{ flexGrow: 1 }}>
		    <Grid container spacing={2} style={{padding:'20px 0'}}>
		      
		      <Grid lg={4} container lgOffset={4}>
		      	<Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
			        Página não encontrada.
			      </Typography>
		      </Grid>

		     </Grid>
		  </Box>
		</Container>
	</Layout>

}