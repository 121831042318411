import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { useAuth } from '../../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import fetch_items from './fetch_items'
import search_filter_mount from './search_filter_mount'
import Table from './Table'
import Sort from './Sort'
import Filters from './Filters'
import Search from './Search'

export default function AnnsTable () {

	const navigate = useNavigate()
  	const { token } = useAuth()

	const [items, setItems] = useState([])
	const [sort, setSort] = useState('date_created_desc')
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState('all')

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		
		if (search_url_query) setSearch(search_url_query)
	}, [])

	// Caso uma busca seja definida
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		queryParams.set('search', search)
		// Se tem busca, adiciona o atributo, se não não
		navigate(search ? `?${queryParams.toString()}` : '')
	}, [search])

	useEffect(() => {
		fetch_items(token).then(items => {
      // console.log(items)
			setItems(items)
		})
	}, [token])

	const mounted_items = items.length > 0
		? search_filter_mount(items, search, filter)
		: null

	return <>

		<Grid item md={3} xs={12}>
			<Typography
				variant="h6"
				component="div"
				sx={{ flexGrow: 1 }}
			>
				Meus Anúncios
			</Typography>
		</Grid>

		<Grid item md={3} xs={12}>
			<Search
				value={search}
				handleSearch={setSearch}
			/>
		</Grid>
		
		<Grid item md={3} xs={12}>
			<Sort
				value={sort}
				handleSort={setSort}
			/>
		</Grid>
		
		<Grid item md={3} xs={12}>
			<Filters
				value={filter}
				handleFilter={setFilter}
			/>
		</Grid>

		<Grid item xs={12}>
			{items.length > 0
				? mounted_items.length > 0
					? <Table
							items={mounted_items}
							sort={sort}
						/>
					: <p>Sem resultados.</p>
				: <p>Carregando...</p>
			}
		</Grid>

	</>

}