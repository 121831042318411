import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { green, red, purple } from '@mui/material/colors'

export default function StatusCell({item}) {

  const color = (opacity) => {
    if (item.status === 'active') return green[opacity]
    if (item.status === 'paused') return red[opacity]
    if (item.status === 'under_review') return purple[opacity]
  }

  return <FiberManualRecordIcon
    sx={{
      color: color(500),
      width: '21px',
      position: 'relative',
      top: '0'
    }}
  />

}