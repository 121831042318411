export default async function fetch_products () {

  return [
    {
      sku: '23685',
      orders: [
        {
          id: '200000101010',
          items: [
            {
              id: 'MLB20202020',
              quantity: 10,
              price: 49
            },
          ]
        },
        {
          id: '300000101010',
          items: [
            {
              id: 'MLB30202020',
              quantity: 10,
              price: 49
            },
            {
              id: 'MLB40202020',
              quantity: 10,
              price: 49
            },
            {
              id: 'MLB70202020',
              quantity: 10,
              price: 49
            },
          ]
        },
        {
          id: '400000101010',
          items: [
            {
              id: 'MLB50202020',
              quantity: 10,
              price: 49
            },
            {
              id: 'MLB60202020',
              quantity: 10,
              price: 49
            },
          ]
        }
      ],
    }
  ]

}