import RelistDialog from '../RelistDialog'

const statusColumn = {
  field: 'relist_actions',
  type: 'relist_actions',
  headerName: '',
  width: 5,
  cellClassName: 'relist_actions',
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params) => <RelistDialog params={params} />,
}

export default statusColumn