import createdDate from './createdDate'
import estimatedDate from './estimatedDate'

import Grid from '@mui/material/Unstable_Grid2'

function List (data) {
  
    return data.map(devolution => {
      
        let reputation = 'Não afetou'
        if (devolution.reputation.affects_reputation === 'affected')
            reputation = <span style={{
            background: '#eb6767',
            padding: '2px 5px',
            borderRadius: '3px',
            color: '#fff',
            fontWeight: 'bold'
        }}>Afetou a Reputação</span>
            
        return <Grid
            xs={12}
            container
            key={devolution.id}
            style={{ borderBottom: '3px solid #eee' }}
        >
        
            <Grid xs={6}>
            <p>
                <strong>Venda: </strong>{devolution.resource_id}<br/>
                <strong>Abertura: </strong>{createdDate(devolution.date_created)}<br/>
    
                <strong>Status: </strong>
                {devolution.status === 'closed' && 'Concluída'}
                {devolution.status === 'opened' && 'Em Andamento'}
                <br/>
                <strong>Reputação: </strong>{reputation}<br/>
            </p>
            </Grid>
    
            <Grid xs={6}>
            <p>
                <strong>Envio da Devolução</strong><br/>
                <strong>Data de Recebimento: </strong>{devolution.details.shipping && estimatedDate(devolution.details.shipping.lead_time.estimated_delivery_time.date)}<br/>
                <strong>Status do Envio: </strong>
                {(devolution.details.shipping && devolution.details.shipping.status === 'pending') && 'Envio Gerado'}
                {(devolution.details.shipping && devolution.details.shipping.status === 'ready_to_ship') && 'Em Preparação'}
                {(devolution.details.shipping && devolution.details.shipping.status === 'shipped') && 'Enviado'}
                {(devolution.details.shipping && devolution.details.shipping.status === 'not_delivered') && 'Não Entregue'}
                {(devolution.details.shipping && devolution.details.shipping.status === 'delivered') && 'Entregue'}
                {(devolution.details.shipping && devolution.details.shipping.status === 'cancelled') && 'Envio Cancelado'}
                <br/>
                <strong>Código de Rastreio: </strong>{devolution.details.shipping && devolution.details.shipping.tracking_number}<br/>
            </p>
            </Grid>
    
        </Grid>
  
    })
  
}

export default List