import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export default function Sort ({ value, onSortSellect }) {
  
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id="sort-select-label">Ordenar</InputLabel>
      <Select
        labelId="sort-select-label"
        id="sort-select"
        value={value}
        onChange={(e) => onSortSellect(e.target.value)}
      >
        <MenuItem key='relevant-first' value='relevant-first'>
          Mais Relevante Primeiro
        </MenuItem>

        <MenuItem key='more-visited-first-7d' value='more-visited-first-7d'>
          Mais Visitado Primeiro | 7 dias
        </MenuItem>

        <MenuItem key='more-visited-first-14d' value='more-visited-first-14d'>
          Mais Visitado Primeiro | 14 dias
        </MenuItem>

        <MenuItem key='more-visited-first-30d' value='more-visited-first-30d'>
          Mais Visitado Primeiro | 30 dias
        </MenuItem>

        <MenuItem key='more-visited-first' value='more-visited-first'>
          Mais Visitado Primeiro | Totais
        </MenuItem>

        <MenuItem key='newer-first' value='newer-first'>
          Mais Novo Primeiro
        </MenuItem>
        
      </Select>
    </FormControl>
  )

}