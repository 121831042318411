import axios from '../../../backend-config'

export default async function fetch_items(id, token){
	try{
			
		const response = await axios.get(`mercadolivre/items/${id}`, {
			headers: { Authorization: token }
		})

		const data = response.data

		if (data.items && !data.items.error) {
			return { item: data.items[0] }
		}		
		console.log(data)

		if (data.items.error && data.items.error === 'error_getting_seller_item') {
			return { error: "Não foi possível carregar o anúncio. Verifique sua integração."}
		}
		
		if (data.items.error && data.items.error === 'internal_error') {
			return { error: "Houve um erro interno. Por favor, tente novamente."}
		}

	} catch (error) {
		
		return { error: "Houve um erro inesperado e não foi possível carregar o anúncio."}
		
	}
}