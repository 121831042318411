import Grid from '@mui/material/Unstable_Grid2'
import Layout from '../../../../layout/panel/Layout'

import useIntegration from './hooks/useIntegration'
import SlackIntegrationForm from './components/SlackIntegrationForm'
import Configurations from './components/Configurations'

export default function PageSlackConfiguration () {

	const { hasIntegration } = useIntegration()
	//const hasIntegration = false

	return <Layout>
		<Grid
			container
			spacing={4}
			mt={1}
			xs={6} xsOffset={3}
			md={6} mdOffset={3}
		>
			<SlackIntegrationForm hasIntegration={hasIntegration} />
      		{hasIntegration && <Configurations />}
		</Grid>
	</Layout>

}