import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../../AuthContext'

import { useGridApiContext } from '@mui/x-data-grid'

import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'

import fetch_item_variations from './fetch_item_variations'
import { mount_item } from '../../mount_item'

function CancelMassiveButton({item_id, rows, parent_grid_ref}){
	const apiRef = useGridApiContext()
	const { token } = useAuth()
	const [changed, setChanged] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => setChanged(rows.filter(row => row.changed === true)), [rows])

	const handleClick = async () => {
		setLoading(true)
		
		const response = await fetch_item_variations(token, item_id)
		const mounted_item = mount_item(response)
		
		// Updates items grid
		parent_grid_ref.current.updateRows([mounted_item])

		// Updates variation grid
		mounted_item.variations.map(variation => apiRef.current.updateRows([variation]))
		
		setLoading(false)
	}

	return <Button
		variant='contained'
		onClick={handleClick}
		disabled={loading || changed.length <= 0}
		size='small'
		sx={{ marginBottom: '10px' }}
	>
		<CancelIcon sx={{height: '20px', marginRight: '5px'}} /> Cancelar Alterações
	</Button>
}

export default CancelMassiveButton