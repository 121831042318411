import axios from '../../../../../backend-config'

export default async function submit_description (token, item_id, description) {
	
  const response = await axios.put(`mercadolivre/items/description`, {
    item: {
      id: item_id,
      description: description,
    }
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
			Accept: 'application/json',
    },
  })
  
  return response.data

}