export default function search_for_title (string, item) {
	const searchStrings = string.split(" ")
  return searchStrings.every(searchString => {
  	
  	if (searchString.startsWith('!')) {
      const termToExclude = searchString.substring(1); // Remove o caractere '!' para obter o termo de busca a ser excluído
      return !item.title.toLowerCase().includes(termToExclude.toLowerCase())
    } else {
      return item.title.toLowerCase().includes(searchString.toLowerCase())
    }
    
  })
}