import Tooltip from '@mui/material/Tooltip'
import MeliTipsMenu from '../MeliTipsMenu'

const skuColumn = {
	field: 'sku',
	width: 80,
	type: 'string',
	sortable: true,
	disableColumnMenu: true,
	renderHeader: () => <Tooltip
	  title='SKU dos produtos no anúncio do Mercado Livre.'
	  arrow
	>
	  <span>SKU</span>
	</Tooltip>,
  renderCell: (params) => <MeliTipsMenu id={params.row.id} tooltip title={params.row.variations === null ? params.row.sku : params.row.variations.map(variation => variation.sku).join(' / ')} />,
}

export default skuColumn