import { useState } from 'react'

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import useSlackChannels from '../hooks/useSlackChannels'

export default function ChangeChannel ({value, setValue, handleSave}) {

    const { slackChannels } = useSlackChannels()
    const [changed, setChanged] = useState(false)

	return <>
        <FormControl fullWidth>
            <Select
                value={value}
                onChange={(e) => {
                    setValue(e.target.value)
                    setChanged(true)
                }}
                size='small'
            >
                <MenuItem value={'undefined'}>Não receber notificações</MenuItem>
                {slackChannels && slackChannels.map(channel => 
                    <MenuItem key={channel.id} value={channel.id}>#{channel.name}</MenuItem>
                )}
            </Select>
        </FormControl>
        
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'end',
                marginTop: '10px',
            }}
        >
            <Button
                variant='contained'
                onClick={() => {
                    handleSave(value)
                    setChanged(false)
                }}
                disabled={!changed}
            >Salvar</Button>
        </Box>
    </>

}