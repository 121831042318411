import Tooltip from '@mui/material/Tooltip'

const shippingCostColumn = {
	field: 'shipping_money',
	width: 60,
	sortable: true,
	disableColumnMenu: true,
	renderHeader: () => (
    <Tooltip
		  title='Valor de frete cobrado pelo Mercado Livre em caso de venda.'
		  arrow
		>
		  <span>Frete</span>
		</Tooltip>
  )
}

export default shippingCostColumn