import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid'

import CancelMassiveButton from './CancelMassiveButton'
import UpdateMassiveButton from './UpdateMassiveButton'

const CustomToolbar = () => {

	const apiRef = useGridApiContext()

  return (
    <GridToolbarContainer>
      <CancelMassiveButton rows={apiRef.current.getSortedRows()} />
      <UpdateMassiveButton rows={apiRef.current.getSortedRows()} />
    </GridToolbarContainer>
  )
}

export default CustomToolbar