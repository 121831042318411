import axios from '../../../../backend-config'

async function fetch_items_update(token, items_data) {

  try {

    const response = await axios.put(`mercadolivre/items`, items_data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })
    return response.data.updated_items

  } catch (error) {

    return error.response.data

  }

}

export default fetch_items_update