import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export default function Sort ({value, handleSort}) {

  return <FormControl style={{ width: '100%' }}>
    <InputLabel id="select-sort-label" size="small">Ordenar</InputLabel>
    <Select
      labelId="select-sort-label"
      id="select-sort"
      value={value}
      label="Tipo"
      onChange={e => handleSort(e.target.value)}
      size="small"
    >
      <MenuItem value='date_created_desc' selected>Mais Novo Primeiro</MenuItem>
      <MenuItem value='date_created_asc'>Mais Antigo Primeiro</MenuItem>
      {/*<MenuItem value='sku_desc'>SKU ▼</MenuItem>*/}
      {/*<MenuItem value='sku_asc'>SKU ▲</MenuItem>*/}
    </Select>
  </FormControl>
}