import { useEffect } from 'react'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Chip from '@mui/material/Chip'
import Tooltip from '@mui/material/Tooltip'

import float_to_money from './float_to_money'

function hoverRow () {

  const linhas = document.querySelectorAll('tr[data-hoverorder]');
  
  linhas.forEach(function(linha) {
    linha.addEventListener("mouseover", function() {
      const hoverOrder = this.getAttribute('data-hoverorder')
      linhas.forEach(function(outraLinha) {
        if (outraLinha.getAttribute('data-hoverorder') === hoverOrder) {
          outraLinha.classList.add('hovered')
        }
      })
    })
    
    linha.addEventListener("mouseout", function() {
      linhas.forEach(function(outraLinha) {
        outraLinha.classList.remove('hovered')
      })
    })
  })

}

export default function Row({order}) {

  useEffect(() => hoverRow(), [])

  let status
  let bg_color

  if (order.shipment) {
    if (order.shipment.status === 'ready_to_ship') {
      if (order.shipment.substatus === 'printed')
        status = 'Pronto para Enviar'
      else if (order.shipment.substatus === 'invoice_pending')
        status = 'Aguardando Nota Fiscal'
      else
        status = 'Despachado'
    }
    else if (order.shipment.status === 'pending')
      status = 'Enviar'
    else if (order.shipment.status === 'shipped')
      status = 'Despachado'
    else if (order.shipment.status === 'delivered')
      status = 'Entregue'
    else if (order.shipment.status === 'cancelled')
      status = 'Cancelado'
    else
      status = order.shipment.status
    
    
    if (status === 'Aguardando Nota Fiscal' || status === 'Enviar')
      bg_color = '#0ff'
    if (status === 'Pronto para Enviar')
      bg_color = '#ff0'
    if (status === 'Despachado')
      bg_color = '#f90'
    if (status === 'Entregue')
      bg_color = '#0f0'
    if (status === 'Cancelado')
      bg_color = '#f20'
  }

  const items_quantity = order.mercadopago_payments ? order.mercadopago_payments.length : 0

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          '& > .MuiTableCell-root': {
            padding: '5px',
            fontSize: '12px'
          },
          '&.hovered': {
            backgroundColor: '#ccc'
          }
        }}
        key={order.id}
        data-hoverorder={order.id}
      >
        {/* id */}
        <TableCell rowSpan={items_quantity} style={{ width: '250px' }}>
          {order.id}<br/>
          {order.date_closed}<br/>
          {order.buyer}
        </TableCell>
        
        <TableCell rowSpan={items_quantity}>
          {
            order.invoice_recipient_name
            && order.invoice_number
            && <Tooltip title={order.invoice_recipient_name} arrow>
              <span>{order.invoice_number}</span>
            </Tooltip>
          }
        </TableCell>

        {/* id pagamento */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? order.mercadopago_payments[0].id
          : ''
        }</TableCell>

        {/* total recebimentos */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? order.mercadopago_payments[0].total_paid_amount
          : ''
        }</TableCell>

        {/* total despesas */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? float_to_money (order.mercadopago_payments[0].total_paid_amount - order.mercadopago_payments[0].net_received_amount)
          : ''
        }</TableCell>

        {/* recebimento liquido */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? order.mercadopago_payments[0].net_received_amount
          : ''
        }</TableCell>

        {/* detalhes despesas */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? order.mercadopago_payments[0].charges.map(charge => <span key={order.mercadopago_payments[0].id+charge.name}>
            {`${float_to_money(charge.amount_original - charge.amount_refunded)} - ${charge.label}`}
            <br/>
          </span>)
          : ''
        }</TableCell>

        {/* status */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? <Chip
              label={order.mercadopago_payments[0].money_release_status_label}
              sx={{
                '&': { background: order.mercadopago_payments[0].money_release_status === 'released' ? 'green' : 'yellow', height: '22px', border: 'none', },
                '& > .MuiChip-label': { padding: '0 5px', }
              }}
              variant="outlined"
            />
          : ''
        }</TableCell>

        {/* data */}
        <TableCell rowSpan={1}>{
          order.mercadopago_payments && order.mercadopago_payments.length > 0
          ? order.mercadopago_payments[0].money_release_date
          : ''
        }</TableCell>
        
      </TableRow>
      
      {order.mercadopago_payments && order.mercadopago_payments.length > 1 && order.mercadopago_payments.shift() && order.mercadopago_payments.map((payment, index) => {
        return <TableRow
          sx={{
            '& > *': { borderBottom: 'unset' },
            '& > .MuiTableCell-root': {
              padding: '5px',
              fontSize: '12px'
            },
            '&.hovered': {
              backgroundColor: '#ccc'
            }
          }}
          key={order.id+payment.id}
          data-hoverorder={order.id}
        >
          
          {/* id pagamento */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? order.mercadopago_payments[index].id
            : ''
          }</TableCell>

          {/* total recebimentos */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? order.mercadopago_payments[index].total_paid_amount
            : ''
          }</TableCell>

          {/* total despesas */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? float_to_money (order.mercadopago_payments[index].total_paid_amount - order.mercadopago_payments[index].net_received_amount)
            : ''
          }</TableCell>

          {/* recebimento liquido */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? order.mercadopago_payments[index].net_received_amount
            : ''
          }</TableCell>

          {/* detalhes despesas */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? order.mercadopago_payments[index].charges.map(charge => <span key={order.mercadopago_payments[index].id+charge.name}>
              {`${float_to_money(charge.amount_original - charge.amount_refunded)} - ${charge.label}`}
              <br/>
            </span>)
            : ''
          }</TableCell>

          {/* status */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? <Chip
                label={order.mercadopago_payments[index].money_release_status_label}
                sx={{
                  '&': { background: order.mercadopago_payments[index].money_release_status === 'released' ? 'green' : 'yellow', height: '22px', border: 'none', },
                  '& > .MuiChip-label': { padding: '0 5px', }
                }}
                variant="outlined"
              />
            : ''
          }</TableCell>

          {/* data */}
          <TableCell rowSpan={1}>{
            order.mercadopago_payments && order.mercadopago_payments.length > 0
            ? order.mercadopago_payments[index].money_release_date
            : ''
          }</TableCell>

        </TableRow>
      })}
    </>
  );
}