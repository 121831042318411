import { useState, useEffect } from 'react'

import { useAuth } from '../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'

function StoreForm(){

	const [loading, setLoading] = useState(true)
	const [storeId, setStoreId] = useState('')
	const [storeName, setStoreName] = useState('')
	const [changed, setChanged] = useState(false)

	const { token } = useAuth()

	function fetch_data() {
		fetch(`https://backend-nodeapp.samuelfoltran.com/user`, {
			headers: { Authorization: token },
		})
		.then(response => response.json())
		.then(data => {
			setChanged(false)
			setLoading(false)
			setStoreId(data.store_id)
			setStoreName(data.store_name)
		})
	}

	useEffect(() => fetch_data(), [])

	const handleSubmit = () => {
		fetch(`https://backend-nodeapp.samuelfoltran.com/user/store`, {
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
			method: 'PUT',
			body: JSON.stringify({
				id: storeId,
				storename: storeName
			})
		})
		.then(response => response.json())
		.then(data => fetch_data())
	}

	return <>
		<Grid xs={12}>
  		<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
        Dados da Minha Loja
      </Typography>
  	</Grid>

		<Grid xs={12}>
			<p>Apenas um nome para idepntificação da sua loja.</p>
  		{
  			!loading
  			? <TextField
		  			label="Nome da Loja"
		  			variant="standard"
		  			fullWidth
		  			value={storeName}
		  			onChange={(e) => {
		  				setChanged(true)
		  				setStoreName(e.target.value)
		  			}}
		  		/>
		  	: <Skeleton variant="rectangular" sx={{width: '100%'}} height={48} />
  		}
  	</Grid>

  	<Grid xs={6} xsOffset={6}>
  		{
  			!loading
  			? <Button
		  			variant="contained"
		  			disabled={!changed}
		  			fullWidth
		  			onClick={handleSubmit}
		  		>
		  			Salvar
		  		</Button>
		  	: <Skeleton variant="rectangular" sx={{width: '100%'}} height={36} />
		  }
  	</Grid>
	</>
}

export default StoreForm