import * as React from 'react';
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../PermissionsContext'

import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'

import AnnsMenu from './AnnsMenu'
import MarketMenu from './MarketMenu'
import AccountMenu from './AccountMenu'
import OrdersMenu from './OrdersMenu'
import ConciliationMenu from './ConciliationMenu'
import ConciliationPaymentsMenu from './ConciliationPaymentsMenu'
import IntegrationsMenu from './IntegrationsMenu'

export default function MenuAppBar() {
  
  const navigate = useNavigate()
  const { has_permission } = usePermissions()

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar variant="dense">
          
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, fontFamily: "'PT Serif', serif" }}
            onClick={() => navigate('/panel')}
          >
            Lyzard
          </Typography>
          
          <Box sx={{
            display: { xs: 'none', md: 'flex' },
            '& > .MuiButtonBase-root': {
              marginLeft: '10px'
            },
            '& > .MuiButtonBase-root': {
              color: 'white',
              textTransform: 'none',
              padding: '0 20px'
            }
          }}>
            <AnnsMenu />
            {has_permission('access_orders') && <OrdersMenu />}
            {has_permission('access_conciliation') && <ConciliationMenu />}
            {has_permission('access_conciliation_payments') && <ConciliationPaymentsMenu />}
            <MarketMenu />
            {has_permission('access_integrations') && <IntegrationsMenu />}
            <AccountMenu />
          </Box>

        </Toolbar>
      </AppBar>
    </Box>
  );
}