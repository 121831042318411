import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'

import TableHeader from './TableHeader'
import Row from './Row'

export default function ({items}) {

  return <div style={{ height: '100%', minHeight: 0, display: 'flex', flexDirection: 'column' }}>
    <div style={{ flex: 1, overflow: 'hidden' }}>

      <TableContainer style={{ maxHeight: 'calc(100vh - 190px)'}}>
        <Table aria-label="sticky table">
          
          <TableHeader />

          <TableBody>
            {items.map( (item, index) => <Row item={item} key={item.id} index={index+1} /> )}
          </TableBody>
        
        </Table>
      </TableContainer>

    </div>
  </div>

}