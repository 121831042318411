import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid'

import CancelMassiveButton from './CancelMassiveButton'
import UpdateMassiveButton from './UpdateMassiveButton'

const CustomToolbar = (props) => {

	const apiRef = useGridApiContext()

  return (
    <GridToolbarContainer>
      <CancelMassiveButton
        item_id={props.item_id}
        rows={apiRef.current.getSortedRows()}
        parent_grid_ref={props.parent_grid_ref}
      />
      <UpdateMassiveButton
        item_id={props.item_id}
        rows={apiRef.current.getSortedRows()}
        parent_grid_ref={props.parent_grid_ref}
      />
    </GridToolbarContainer>
  )
}

export default CustomToolbar