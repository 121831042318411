import axios from '../../../../../backend-config'

export default async function VisitsFetch (token, id) {

	const response = await axios(`mercadolivre/metrics/item/${id}`, {
		headers: { Authorization: token }
	})
	
	return response.data.metrics

}