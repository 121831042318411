import Tooltip from '@mui/material/Tooltip'

import { float_to_money, money_to_float } from '../mount_item'

const handleChangeNet = params => {

  // Verifyy if data really changed
	if (params.value === params.row.net_money) return params.row

  const floatNet = money_to_float(params.value)

  const price        = (floatNet + parseFloat(params.row.listing_cost.fixed) + parseFloat(params.row.shipping_cost)) / (1 - parseFloat(params.row.listing_cost.percentage / 100))
  const listing_cost = price * parseFloat(params.row.listing_cost.percentage / 100) + parseFloat(params.row.listing_cost.fixed)

  const updated= {
    ...params.row,
    price: price.toFixed(2),
    price_money: float_to_money(price),
    listing_money: float_to_money(listing_cost),
    net: floatNet,
    net_money: float_to_money(floatNet),
    changed: true,
  }
  updated.listing_cost.value = listing_cost.toFixed(2)

  return updated

}

const netColumn = {
  field: 'net_money',
  width: 65,
  sortable: false,
  disableColumnMenu: true,
  editable: true,
  valueSetter: handleChangeNet,
  renderHeader: () => (
    <Tooltip
      title='Valor repassado ao vendedor pelo Mercado Livre em caso de venda.'
      arrow
    >
      <span>Repasse</span>
    </Tooltip>
  )
}

export default netColumn