import { useState } from 'react'

import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TablePagination from '@mui/material/TablePagination'

import Row from './Row'

export default function ({items, sort}) {

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)

  const sortedItems = () => {
    console.log(items)
    if (sort === 'date_created_asc') {
      return [...items].sort((a, b) => new Date(a.date_created) - new Date(b.date_created))
    }
    if (sort === 'date_created_desc') {
      return [...items].sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
    }
    
    if (sort === 'sku_desc') {
      //return [...items].sort((a, b) => a.sku.localeCompare() - b.sku.toString())
      return [...items].sort((a, b) => a.sku.localeCompare(b.sku))
    }
    if (sort === 'sku_asc') {
      //return [...items].sort((a, b) => b.sku.toString() - a.sku.toString())
      return [...items].sort((a, b) => b.sku.localeCompare(a.sku))
    }
    
    return items
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return <div style={{ height: '100%', minHeight: 0, display: 'flex', flexDirection: 'column' }}>
    <div style={{ flex: 1, overflow: 'hidden' }}>

      <TableContainer style={{ maxHeight: 'calc(100vh - 185px)'}}>
        <Table aria-label="sticky table">
          
          <TableBody>
            {sortedItems()
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map( (item) => <Row item={item} key={item.id} /> )
            }
          </TableBody>
        
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[25, 50, 100]}
        component="div"
        count={items.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </div>
  </div>

}