import { useEffect } from 'react'

import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Chip from '@mui/material/Chip'

function hoverRow () {

  const linhas = document.querySelectorAll('tr[data-hoverorder]');
  
  linhas.forEach(function(linha) {
    linha.addEventListener("mouseover", function() {
      const hoverOrder = this.getAttribute('data-hoverorder')
      linhas.forEach(function(outraLinha) {
        if (outraLinha.getAttribute('data-hoverorder') === hoverOrder) {
          outraLinha.classList.add('hovered')
        }
      })
    })
    
    linha.addEventListener("mouseout", function() {
      linhas.forEach(function(outraLinha) {
        outraLinha.classList.remove('hovered')
      })
    })
  })

}

export default function Row({order}) {

  useEffect(() => hoverRow(), [])

  let status
  let bg_color

  if (order.shipment) {
    if (order.shipment.status === 'ready_to_ship') {
      if (order.shipment.substatus === 'printed')
        status = 'Pronto para Enviar'
      else if (order.shipment.substatus === 'invoice_pending')
        status = 'Aguardando Nota Fiscal'
      else
        status = 'Despachado'
    }
    else if (order.shipment.status === 'pending')
      status = 'Enviar'
    else if (order.shipment.status === 'shipped')
      status = 'Despachado'
    else if (order.shipment.status === 'delivered')
      status = 'Entregue'
    else if (order.shipment.status === 'cancelled')
      status = 'Cancelado'
    else
      status = order.shipment.status
    
    
    if (status === 'Aguardando Nota Fiscal' || status === 'Enviar')
      bg_color = '#0ff'
    if (status === 'Pronto para Enviar')
      bg_color = '#ff0'
    if (status === 'Despachado')
      bg_color = '#f90'
    if (status === 'Entregue')
      bg_color = '#0f0'
    if (status === 'Cancelado')
      bg_color = '#f20'
  }

  const items_quantity = order.items.length

  return (
    <>
      <TableRow
        sx={{
          '& > *': { borderBottom: 'unset' },
          '& > .MuiTableCell-root': {
            padding: '5px',
            fontSize: '12px'
          },
          '&.hovered': {
            backgroundColor: '#ccc'
          }
        }}
        key={order.id+order.items[0].id}
        data-hoverorder={order.id}
      >
        <TableCell rowSpan={items_quantity} style={{ width: '250px' }}>
          {order.id}<br/>
          {order.date_closed}<br/>
          {order.buyer}
        </TableCell>
        <TableCell rowSpan={items_quantity} sx={{ width: '100px' }}>
          <Chip
            label={status}
            sx={{
              '&': { background: bg_color, height: '22px', border: 'none', },
              '& > .MuiChip-label': { padding: '0 5px', }
            }}
            variant="outlined"
          />
        </TableCell>
        <TableCell rowSpan={items_quantity}>{order.invoice_number}</TableCell>
        
        <TableCell rowSpan={1}>{order.items[0].id}</TableCell>
        <TableCell rowSpan={1} sx={{ width: '250px' }}><nobr>{order.items[0].title}</nobr></TableCell>
        <TableCell rowSpan={1}>{order.items[0].sku}</TableCell>
        <TableCell rowSpan={1}>{order.items[0].quantity}</TableCell>
        <TableCell rowSpan={1}>{order.items[0].total_value}</TableCell>
        
        <TableCell rowSpan={items_quantity}>{order.total_items_revenue}</TableCell>
      </TableRow>
      
      {order.items.length > 1 && order.items.shift() && order.items.map((item, index) => {
        return <TableRow
          sx={{
            '& > *': { borderBottom: 'unset' },
            '& > .MuiTableCell-root': {
              padding: '5px',
              fontSize: '12px'
            },
            '&.hovered': {
              backgroundColor: '#ccc'
            }
          }}
          key={order.id+order.items[index].id}
          data-hoverorder={order.id}
        >
          <TableCell>{order.items[index].id}</TableCell>
          <TableCell><nobr>{order.items[index].title}</nobr></TableCell>
          <TableCell>{order.items[index].sku}</TableCell>
          <TableCell>{order.items[index].quantity}</TableCell>
          <TableCell>{order.items[index].total_value}</TableCell>
        </TableRow>
      })}
    </>
  );
}