import Tooltip from '@mui/material/Tooltip'

const soldQuantityColumn = {
  field: 'sold_quantity',
  width: 55,
  type: 'number',
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip
      title='Únidades vendidas do anúncio no Mercado Livre.'
      arrow
    >
      <span>Vendas</span>
    </Tooltip>
  )
}

export default soldQuantityColumn