import axios from '../../../../backend-config'

function dateFrom(ISODate) {
  const date = new Date(ISODate);
  
  const dia = String(date.getDate()).padStart(2, '0')
  const mes = String(date.getMonth() + 1).padStart(2, '0')
  const ano = date.getFullYear()
  
  // 2015-07-01T00:00:00.000-00:00
  const formatado = `${ano}-${mes}-${dia}T00:00:00.000-00:00`
  return formatado
  
}

function dateTo(ISODate) {
  const date = new Date(ISODate);
  
  // Formata a data e hora para "dd/mm/aaaa hh:mm"
  const dia = String(date.getDate()).padStart(2, '0')
  const mes = String(date.getMonth() + 1).padStart(2, '0')
  const ano = date.getFullYear()
  const hora = String(date.getHours()).padStart(2, '0')
  const minuto = String(date.getMinutes()).padStart(2, '0')
  let segundo = String(date.getSeconds()).padStart(2, '0');
	let milissegundo = String(date.getMilliseconds()).padStart(3, '0');

  // 2015-07-01T00:00:00.000-00:00
  const formatado = `${ano}-${mes}-${dia}T${hora}:${minuto}:${segundo}.${milissegundo}-03:00`;
  return formatado
  
}

export default async function fetch_orders (token) {

	const date_to = new Date()
	const date_from = new Date()
	date_from.setDate(date_from.getDate() - 15)

  const url = `mercadolivre/orders?date_from=${dateFrom(date_from)}&date_to=${dateTo(date_to)}`
  const response = await axios.get(url, {
		headers: { Authorization: token }
	})
  
  return response.data.orders

}