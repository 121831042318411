import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../../AuthContext'

import { useGridApiContext } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import OutboundIcon from '@mui/icons-material/Outbound'

import fetch_item_variations_update from './fetch_item_variations_update'
import { mount_item } from '../../mount_item'

function UpdateMassiveButton ({item_id, rows, parent_grid_ref}) {

	const apiRef = useGridApiContext()
	const { token } = useAuth()
	const [changed, setChanged] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => setChanged(rows.filter(row => row.changed === true)), [rows])

	const handleClick = async () => {
		setLoading(true)

		const itemsToUpdate = {
			item: {
				id: item_id,
				variations: rows.map(variation => {
					return {
						id: variation.id,
						quantity: variation.quantity,
						price: parseFloat(variation.price),
					}
				})
			}
		}

		const response = await fetch_item_variations_update(token, JSON.stringify(itemsToUpdate))
		const mounted_item = mount_item(response.updated_items[0])
		
		// Updates items grid
		parent_grid_ref.current.updateRows([mounted_item])

		// Updates variations grid
		mounted_item.variations.map(variation => apiRef.current.updateRows([variation]))

		setLoading(false)
	}

	return (changed.length > 0)
	
	? <Tooltip title='Clique para subir todas as alterações pendentes.'>
		<Button
			variant="contained"
			onClick={handleClick}
			size='small'
			sx={{
				marginLeft: 'auto',
				marginBottom: '10px'
			}}
			disabled={loading}
		>
			<>
				<OutboundIcon sx={{height: '20px', marginRight: '5px'}} />
				Subir {changed.length} {changed.length === 1
					? 'alteração pendente'
					: 'alterações pendentes'}
			</>
		</Button>
	</Tooltip>

	: <Button
		variant="contained"
		size='small'
		disabled
		sx={{
			marginLeft: 'auto',
			marginBottom: '10px'
		}}
	>
		Não há alterações pendentes.
	</Button>
	
}

export default UpdateMassiveButton