import { useState, useEffect } from 'react'

import { useAuth } from '../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

function MeliIntegrationForm(){

	const [hasIntegration, setHasIntegration] = useState(false)

	const { token } = useAuth()

	function fetch_data() {
		fetch(`https://backend-nodeapp.samuelfoltran.com/user`, {
			headers: { Authorization: token },
		})
		.then(response => response.json())
		.then(data => {
			//console.log(data)
			setHasIntegration(data.store_has_integration)
		})
	}

	useEffect(() => fetch_data(), [])

	const mercadolivre_auth = () => {
		fetch('https://backend-nodeapp.samuelfoltran.com/integrations/mercadolivre/auth', {
			headers: { Authorization: token }
		})
		.then(response => response.json())
		.then(data => {
			if(data.error) alert(data.error)
			else window.location.href = data.url
		})
	}

	return <>
		<Grid xs={12} mt={2}>
  		<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
        Integração com Mercado Livre
      </Typography>
  	</Grid>

  	{!hasIntegration && <Grid xs={12}>
  		<p>Sua conta ainda não está integrada ao mercado livre. Clicando no botão abaixo, você será redirecionado para a página de permissão do mercado livre.</p>
  		
  		<Button
  			variant="contained"
  			fullWidth
  			onClick={mercadolivre_auth}
  		>
  			Integrar ao Mercado Livre
  		</Button>
  	</Grid>}

  	{hasIntegration && <Grid xs={12}>
		<p>Sua conta está integrada ao mercado livre.</p>
			{/*<p>Sua conta está integrada ao mercado livre. Clicando no botão abaixo, você desfaz a integração ao mercado livre.</p>
			
			<Button
				variant="contained"
				fullWidth
				onClick={() => alert('Esta função ainda não está ativa. Consulte o desenvolvedor.')}
				color='error'
			>
				Cancelar Integração
			</Button>*/}
		</Grid>}
	</>

}

export default MeliIntegrationForm