import Tooltip from '@mui/material/Tooltip'
import VariationsDialog from '../VariationsDialog'

import { GridEditInputCell } from '@mui/x-data-grid'

const handleChangeQuantity = (params) => {
	
	// Verifyy if data really changed
	if (params.value === params.row.quantity) return params.row

	const quantity = params.value > 0 ? params.value : 0

	const updated = {
		...params.row,
		quantity: quantity,
		changed: true
	}

	return updated
}

const quantityColumn = {
  field: 'quantity',
  width: 60,
  type: 'number',
  disableColumnMenu: true,
  editable: true,
  valueSetter: handleChangeQuantity,
  renderHeader: () => <Tooltip
	  title='Quantidade disponível para venda.'
	  arrow
	>
	  <span>Estoque</span>
	</Tooltip>,
  renderCell: params => params.row.variations === null && params.row.quantity,
  renderEditCell: params => {
  	return params.row.variations === null
  	? <GridEditInputCell {...params} />
  	: ''
  },
}

export default quantityColumn