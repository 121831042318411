import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../PermissionsContext'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function AnnsMenu () {
	
	const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate()
  const { has_permission } = usePermissions()

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

	return (has_permission('access_anns') || has_permission('access_anns')) && <>
		<Button
      onClick={handleMenu}
    >Anúncios</Button>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {has_permission('access_anns') && <MenuItem onClick={() => navigate('/panel/anns')}>Meus Anúncios</MenuItem>}
      {has_permission('access_anns_details') && <MenuItem onClick={() => navigate('/panel/ann-details')}>Detalhes do Anúncio</MenuItem>}
    </Menu>
	</>

}