import { useState, useEffect } from 'react'

import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import fetch_products from './fetch_products'

export default function ProductsList () {

  const [products, setProducts] = useState([])

  useEffect(() => {
    fetch_products().then(response => setProducts(response))
  }, [])

  let total_items = 0
  products && products.forEach(product => {
    product.orders.forEach(order => {
      total_items += order.items.length
    })
  })

  return products.length > 0 && <TableContainer component={Paper}>
  <Table sx={{ minWidth: 650 }} aria-label="simple table">
    
    <TableHead>
      <TableRow>
        <TableCell>SKU</TableCell>
        <TableCell>Vendas</TableCell>
        <TableCell>Anúncios</TableCell>
      </TableRow>
    </TableHead>
    
    <TableBody>
      
      {products.map(product => <>
        
        <TableRow key={product.sku+'-'+product.orders[0].id}>
          <TableCell rowSpan={total_items}>{product.sku}</TableCell>
          <TableCell rowSpan={product.orders[0].items.length}>{product.orders[0].id}</TableCell>
          <TableCell rowSpan={1}>{product.orders[0].items[0].id}</TableCell>
        </TableRow>

        {product.orders.length > 1 && product.orders.shift() && product.orders.map((order) => <>
          
          <TableRow key={product.sku+'-'+order.id}>
            <TableCell rowSpan={order.items.length}>{order.id}</TableCell>
            <TableCell rowSpan={1}>{order.items[0].id}</TableCell>
          </TableRow>

          {order.items.length > 1 && order.items.shift() && order.items.map((item) => 
            <TableRow key={product.sku+'-'+order.id+'-'+item.id}>
              <TableCell rowSpan={1}>{item.id}</TableCell>
            </TableRow>
          )}

        </>)}

      </>)}

    </TableBody>
    
  </Table>
</TableContainer>

}