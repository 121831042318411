import Tooltip from '@mui/material/Tooltip'
import MeliTipsMenu from '../MeliTipsMenu'

const titleColumn = {
  field: 'title',
  minWidth: 360,
  sortable: false,
  disableColumnMenu: true,
  renderHeader: () => <Tooltip
    title='Título do anúncio no Mercado Livre.'
    arrow
  >
    <span>Título</span>
  </Tooltip>,
  renderCell: (params) => <MeliTipsMenu id={params.row.id} title={params.row.title} />,
}

export default titleColumn