import Grid from '@mui/material/Unstable_Grid2'

import Table from './Table'

export default function AnnsList ({items}) {

  return <>

    <Grid xs={12}>
      <Table items={items} />
    </Grid>

  </>

}