const register_api = (user, callback) => {
  fetch(`https://backend-nodeapp.samuelfoltran.com/user/register`, {
  //fetch(`http://localhost:3001/user/register`, {
    method: 'post',
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user)
  })
  .then(response => response.json())
  .then(data => callback(data))
}

export default register_api