import { useState, useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import TextInput from './TextInput'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import submit_attribute_alteration from '../submit_attribute_alteration'

import { useAuth } from '../../../../../../AuthContext'

export default function NumberUnitSelect ({attribute, item_value, item_unit, item_id}) {
	
	const {token} = useAuth()

	const [value, setValue] = useState(item_value !== null ? item_value : '')
	const [initialValue, setInitialValue] = useState(item_value !== null ? item_value : '')
	const [unit, setUnit] = useState(item_unit !== null ? item_unit : '')
	const [initialUnit, setInitialUnit] = useState(item_unit !== null ? item_unit : item_unit)
	const [changed, setChanged] = useState(false)
	const [notApplied, setNotApplied] = useState(item_value === null ? true : false)
	const [initialNotApplied, setInitialNotApplied] = useState(item_value === null ? true : false)

	const handleClickSubmit = async () => {
		
		const response = notApplied
		// for Not Applied
		? await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_id: "-1",
				value_name: null
			})
		// for non Not Applied
		: await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_name: `${value} ${unit}`,
			})

		if (response.item) {
			setInitialValue(value)
			setChanged(false)
		} else {
			alert('Ocorreu um erro ao enviar.')
		}

	}

	const handleClickCancel = () => {
		setValue(initialValue)
		setNotApplied(initialNotApplied)
		setChanged(false)
	}

	useEffect(() => {
		if (value !== initialValue || notApplied !== initialNotApplied)
			setChanged(true)
		else
			setChanged(false)
	}, [value, notApplied])
	
	return <Grid lg={12} container spacing={2} style={{ marginBottom: '15px' }}>
		
		{!notApplied && <>
			
			<Grid lg={8} sx={{ pb: 0 }}>
				<TextInput
		  		key={attribute.id}
		  		value={value}
		  		label={attribute.name}
		  		setValue={(v) => setValue(v)}
		  	/>
			</Grid>

			<Grid lg={4} sx={{ pb: 0 }}>
				<FormControl size="small" fullWidth>
				  <InputLabel id={attribute.id}>
				  	Unidade
				  </InputLabel>
				  <Select
				    labelId={attribute.id}
				    id={attribute.id}
				    value={unit}
				    label='Unidade'
				    onChange={(e) => setUnit(e.target.value)}
				  >
				  	{attribute.allowed_units.map(u => <MenuItem value={u.id} key={u.id}>{u.name}</MenuItem>)}
				  </Select>
				</FormControl>
			</Grid>
		
		</>}

		{notApplied && <Grid lg={12} sx={{ pb: 0 }}>
			<TextInput
				value='Não se aplica'
				label={attribute.name}
				disabled
			/>
		</Grid>}

		{
			(attribute.tags.required !== true && attribute.tags.fixed !== true) &&
			<Grid lg={12} sx={{ pt: 0, pb: 0 }}>
				<FormControlLabel
					control={<Checkbox
						checked={notApplied}
						onClick={() => {
							setChanged(true)
							setNotApplied(!notApplied)
						}}
					/>}
					label="Não se aplica"
				/>
			</Grid>
		}

		{changed && <Grid lg={12}>
			<div style={{ display: 'flex' }}>
			
				<Button onClick={handleClickCancel} >
					Cancelar
				</Button>

				<Button onClick={handleClickSubmit} variant='contained' sx={{ marginLeft: 'auto', order: 2 }} >
					Alterar
				</Button>

			</div>
		</Grid>}

	</Grid>

}