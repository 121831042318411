import Tooltip from '@mui/material/Tooltip';

const listingTypeColumn = {
  field: 'listing_type_actions',
  type: 'listing_type_actions',
  headerName: '',
  width: 90,
  disableColumnMenu: true,
  sortable: false,
  cellClassName: 'listing_type_actions',
  renderCell: (params) => {

    let description
    let tootip_title
    let background
    
    const isFull = params.row.isFull && <Tooltip
      title='Entrega Full'
      placement="bottom"
      arrow
    >
      <span style={{
        background: 'ForestGreen',
        color: 'white',
        borderRadius: '3px',
        fontSize: '11px',
        fontWeight: '700',
        padding: '0 4px',
        marginLeft: '3px' }}
      >
        Full
      </span>
    </Tooltip>

    const isFreeShipping = params.row.isFreeShipping && <Tooltip
    title='Frete Grátis'
    placement="bottom"
    arrow
    >
      <span style={{
        background: '#00BFFF',
        color: 'white',
        borderRadius: '3px',
        fontSize: '11px',
        fontWeight: '700',
        padding: '0 4px',
        marginLeft: '3px' }}
      >
        FG
      </span>
    </Tooltip>

    const hasManufacturingTime = params.row.manufacturing_time !== 0 && <Tooltip
    title={`Tempo de Disponibilidade (${params.row.manufacturing_time})`}
    placement="bottom"
    arrow
    >
      <span style={{
        background: '#00BFCC',
        color: 'white',
        borderRadius: '3px',
        fontSize: '11px',
        fontWeight: '700',
        padding: '0 4px',
        marginLeft: '3px' }}
      >
        TD
      </span>
    </Tooltip>

    // Classic
    if (params.row.listing_type === 'gold_special') {
      description = 'C'
      tootip_title = `Este é um anúncio Clássico`
      background = 'yellow'
    }

    // Premium
    if (params.row.listing_type === 'gold_pro') {
      description = 'P'
      tootip_title = `Este é um anúncio Premium`
      background = 'orange'
    }

    return <>
      <Tooltip
        title={tootip_title}
        placement="left"
        arrow
      >
        <span style={{
          background: background,
          borderRadius: '3px',
          fontSize: '11px',
          fontWeight: '700',
          padding: '0 4px' }}
        >
          {description}
        </span>
      </Tooltip>
      {isFreeShipping}
      {hasManufacturingTime}
      {isFull}
    </>
  },
}

export default listingTypeColumn