import { useState, useEffect } from 'react'

import { useAuth } from '../../../AuthContext'
import axios from '../../../backend-config'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Skeleton from '@mui/material/Skeleton'

function AccountForm(){

	const [loading, setLoading] = useState(true)
	const [id, setId] = useState('')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [changed, setChanged] = useState(false)

	const [emailError, setEmailError] = useState(false)

	const { token } = useAuth()

	async function fetch_data() {
		const response = await axios.get(`user`, {
			headers: { Authorization: token },
		})
		const data = response.data

		setEmailError(false)
		setChanged(false)
		setLoading(false)
		setId(data.user_id)
		setName(data.name)
		setEmail(data.email)
	}

	useEffect(() => {
		fetch_data()
	}, [])

	async function handleSubmit () {
		const response = await axios.put(`user/profile`, { id, name, email }, {
			headers: {
				Authorization: token,
				"Content-Type": "application/json",
			},
		})
		const data = response.data
		
		if (data.error) {
			if (data.error === 'email_already_exists') setEmailError(true)
		}

		else fetch_data()
	}

	return <>
		<Grid xs={12}>
  		<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
        Meus Dados
      </Typography>

      <p>Seus dados de acesso e identificação na Lyzard.</p>
  	</Grid>

		<Grid xs={12}>
  		{
  			!loading
  			? <TextField
		  			label="Nome"
		  			variant="standard"
		  			fullWidth
		  			value={name}
		  			disabled={name===''}
		  			onChange={(e) => {
		  				setChanged(true)
		  				setName(e.target.value)
		  			}}
		  		/>
		  	: <Skeleton variant="rectangular" sx={{width: '100%'}} height={48} />
		  }
  	</Grid>

  	<Grid xs={12}>
  		{
  			!loading
  			? <TextField
		  			label="E-mail"
		  			variant="standard"
		  			fullWidth
		  			value={email}
		  			disabled={email===''}
		  			error={emailError}
		  			onChange={(e) => {
		  				setChanged(true)
		  				setEmail(e.target.value)
		  			}}
		  		/>
		  	: <Skeleton variant="rectangular" sx={{width: '100%'}} height={48} />
		  }
  	</Grid>

  	<Grid xs={6} xsOffset={6}>
  		{
  			!loading
  			? <Button
		  			variant="contained"
		  			disabled={!changed}
		  			fullWidth
		  			onClick={handleSubmit}
		  		>
		  			Salvar
		  		</Button>
		  	: <Skeleton variant="rectangular" sx={{width: '100%'}} height={36} />
		  }
  	</Grid>
	</>
}

export default AccountForm