import PromotionsDialog from '../PromotionsDialog'

const promotionsColumn = {
	field: 'promotions_actions',
	headerName: '',
	width: 10,
	type: 'string',
	disableColumnMenu: true,
	sortable: false,
	renderCell: (params) => <PromotionsDialog item={params.row} />
}

export default promotionsColumn