import { useAuth } from '../../../../../AuthContext'
import axios from '../../../../../backend-config'
import { useState, useEffect } from 'react'

function useIntegration () {

    const [hasIntegration, setHasIntegration] = useState(false)
    const { token } = useAuth()

    async function fetch_data() {
		const response = await axios.get(`slack/integration`, {
			headers: { Authorization: token }
		})
		const data = response.data
		setHasIntegration(data.store_has_integration)
	}

    useEffect(() => {
		fetch_data()
	}, [])

    return {
		hasIntegration,
    }

}

export default useIntegration