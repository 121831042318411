import axios from '../../../../../backend-config'

export default async function submit_item_relist (token, id) {

  try {
    
    const response = await axios.post(`/mercadolivre/items/${id}/relist`, {}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: token
      },
    })
    
    return response.data

  } catch (error) {

    return error.response.data

  }

}