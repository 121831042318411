import TableRow from '@mui/material/TableRow'

import Cell from './Cell'
import ListingTypeCell from './ListingTypeCell'
import DateCell from './DateCell'
import MetricsDialog from './MetricsDialog'
import MoneyCell from './MoneyCell'

export default ({item, index}) => {

  const cellProps = {
    id: item.id,
    seller: item.seller_nickname
  }

  return <TableRow
    sx={{
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      '& > .MuiTableCell-root': {
        padding: '5px',
        fontSize: '11px'
      },
    }}
  >

    <Cell
      {...cellProps}
      val={index}
    />
    
    <Cell
      {...cellProps}
      val={
        <img
          style={{
            width: 50,
            height: 50,
            display: 'block',
          }}
          src={item.thumbnail}
        />
      }
    />

    <Cell
      {...cellProps}
      val={<>
        {item.id}
        <ListingTypeCell item={item} /><br/>
        Criado em <DateCell value={item.date_created} />
      </>}
    />

    <Cell
      {...cellProps}
      val={<>
        {item.title}<br/>
        {item.seller_nickname}
      </>}
    />
    
    <Cell
      {...cellProps}
      val={<MoneyCell value={item.price} />}
      align="right"
      //sx={{ borderLeft: '3px solid #eee' }}
    />

  </TableRow>

}