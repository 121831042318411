import axios from '../../../backend-config'

export default async function fetch_seller_details(seller) {

  const response = await axios.get(`https://api.mercadolibre.com/sites/MLB/search?nickname=${seller}`)
  const data = await response.data

  const response2 = await axios.get(`https://api.mercadolibre.com/users/${data.seller.id}`)
  const data2 = await response2.data

  return {
    id: data.seller.id,
    name: data.seller ? data.seller.nickname : null,
    items: data.paging.total,
    transactions: data2.seller_reputation ? data2.seller_reputation.transactions.total : null,
    /*
    // Dados descontinuados na atualização da api em 06/12/2023
    registration_date: data.seller ? data.seller.registration_date : null,
    metrics: data.seller.seller_reputation ? data.seller.seller_reputation.metrics : null,
    
    */
  }

}