import {useState, useEffect} from 'react'
import { useAuth } from '../../../AuthContext'
import axios from '../../../backend-config'

import Grid from '@mui/material/Unstable_Grid2'

async function fetch_nots (token) {
    const response = await axios.get(`mercadolivre/notifications`, {
        headers: { Authorization: token }
    })

    const data = response.data
    console.log(data)
    
    return data
}

function NotificationsList () {

    const { token } = useAuth()
    const [nots, setNots] = useState(null)

    useEffect(() => {
        fetch_nots(token).then(response => {
            setNots(response.notifications)
        })
    }, [token])

    return <Grid lg={12}>
        
        {nots === null && <p>Carregando</p>}
        {nots !== null && nots.length === 0 && <p>Nenhuma notificação a mostrar</p>}
        {nots !== null && nots.length > 0 && <ul>
            {nots.map(not => <li key={not.id}>
                <pre>
                    {JSON.stringify(not, null, 2)}
                </pre>
            </li>)}
        </ul>}

    </Grid>

}

export default NotificationsList