import axios from '../../../../../backend-config'

export default async function submit_attribute_alteration (token, item_id, attribute) {
	
  const response = await axios.put(`mercadolivre/items/attribute`, {
    id: item_id,
    attribute: attribute,
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
			Accept: 'application/json',
    },
  })
  
  return response.data

}