export default async function submit_title (token, item_id, title) {
	
  const response = await fetch(`https://backend-nodeapp.samuelfoltran.com/mercadolivre/items_v2`, {
  //const response = await fetch(`http://localhost:3001/mercadolivre/items_v2`, {
    method: 'PUT',
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
			Accept: 'application/json',
    },
    body: JSON.stringify({items: [
      {
        id: item_id,
        title: title,
      }
    ]})
  })
  const data = await response.json()
  return data

}