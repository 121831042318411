import { useState, useEffect } from 'react'
import { useAuth } from '../../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import fetch_packs from './fetch_packs'

function displayDate(dateTimeString) {
	const dateTime = new Date(dateTimeString);
	
	const year = dateTime.getFullYear();
	const month = ("0" + (dateTime.getMonth() + 1)).slice(-2); // Add leading zero if needed
	const day = ("0" + dateTime.getDate()).slice(-2); // Add leading zero if needed
	const hours = ("0" + dateTime.getHours()).slice(-2); // Add leading zero if needed
	const minutes = ("0" + dateTime.getMinutes()).slice(-2); // Add leading zero if needed
	
	return `${year}/${month}/${day} ${hours}:${minutes}`;
}

function displayPaymentStatus (mp_status) {
	switch (mp_status) {
		case 'approved': return 'Aprovado'
		case 'rejected': return 'Rejeitado'
		case 'pending': return 'Pendente'
		case 'cancelled': return 'Cancelado'
		case 'refunded': return 'Devolvido'
		default: return mp_status
	}
}

function getFiscalAmount (fiscalAmounts, search, index) {
	const amount = fiscalAmounts
		? fiscalAmounts.find(amount => amount.name.toLowerCase() === search)
		: null
	
	// Simples Nacional
	if (amount === undefined && search === 'icms') {
		const simples_amount = fiscalAmounts.find(amount => amount.name.toLowerCase() === 'icms_simples')
		return simples_amount.attributes[index]
	}

	return amount
	  ? amount.attributes[index]
		: null
}

function getChargeAmount (charges, name) {
	const charge = name === 'mp_fee'
		? charges.find(charge => charge.name === name || charge.name === 'mercadopago_fee' )
		: charges.find(charge => charge.name === name)
	
	return charge
		? charge.amount_original/* - charge.amount_refunded*/
		: null
}

function displayDifal (invoice) {
	const amount = invoice.fiscal_amounts.find(amount => amount.name.toLowerCase() === 'icms')

	// Simples Nacional
	if (amount === undefined) {
		return null
	}

	const difal = amount.attributes.vicmsufdest
	return difal > 0 ? difal : ''
}


const mouse_over = (e) => {
	const lines = document.querySelectorAll('tr[data-hoverrow]')
	lines.forEach(line => {
		if (line.getAttribute('data-hoverrow') === e.currentTarget.dataset.hoverrow) {
			line.classList.add('hovered')
		}
	})
}

const mouse_out = () => {
	const lines = document.querySelectorAll('tr[data-hoverrow]')
	lines.forEach(line => line.classList.remove('hovered'))
}

export default function OrdersList () {

	const { token } = useAuth()

	const [packs, setPacks] = useState([])
	
	// On load page
	useEffect(() => {
		fetch_packs(token).then(packs => {
			//console.log('fetch_packs packs', packs)
			setPacks(packs)
		})
	}, [token])

	if (packs.length > 0) {

		return <>

			<Grid lg={12} container>
				
				<TableContainer sx={{ maxHeight: '73vh' }}>
					<Table stickyHeader aria-label="sticky table">
						
						<TableHead sx={{ overflowX: "initial" }} >
							<TableRow
								sx={{
									'& > .MuiTableCell-root': {
										padding: '5px',
										fontSize: '11px'
									},
								}}
							>
								<TableCell>Venda</TableCell>

								<TableCell
									style={{
										borderLeft: '3px solid #eee',
									}}
								>ID do<br/>Pagamento</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Recebimento<br/>Total</TableCell>
								<TableCell>Taxa ML</TableCell>
								<TableCell>Taxa MP</TableCell>
								<TableCell>Taxa ME</TableCell>
								<TableCell>Taxa FULL</TableCell>
								<TableCell>Juros</TableCell>
								<TableCell>Recebimento<br/>Líquido</TableCell>
								<TableCell>Devoluções</TableCell>

								<TableCell
									style={{
										borderLeft: '3px solid #eee',
									}}
								>Nota Fiscal</TableCell>
								<TableCell>Emissão</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>DIFAL</TableCell>
								<TableCell>ICMS</TableCell>
								<TableCell>IPI</TableCell>
								<TableCell>PIS</TableCell>
								<TableCell>IBPT</TableCell>
								<TableCell>COFINS</TableCell>
							</TableRow>
						</TableHead>
						
						<TableBody>
							{packs.map((pack, pack_index) => <>
								<TableRow
									key={pack_index}
									sx={{
										'& > .MuiTableCell-root': {
											padding: '5px',
											fontSize: '11px'
										},
										'&.hovered': {
											backgroundColor: '#eee'
										}
									}}
									data-hoverrow={pack.pack_id}
									onMouseOver={mouse_over}
									onMouseOut={mouse_out}
								>

									<TableCell rowSpan={pack.payments.length}>
										{pack.pack_id}<br/>
										{displayDate(pack.date_created)}
									</TableCell>
									
									<TableCell rowSpan={1} style={{ borderLeft: '3px solid #eee', }}>
										{pack.payments[0].id}
									</TableCell>
									
									<TableCell rowSpan={1}>
										{displayPaymentStatus(pack.payments[0].status)}
									</TableCell>
									
									<TableCell>
										{pack.payments[0].total_paid_amount}
									</TableCell>

									<TableCell>
										{getChargeAmount(pack.payments[0].charges, 'ml_fee')}
									</TableCell>

									<TableCell>
										{getChargeAmount(pack.payments[0].charges, 'mp_fee')}
									</TableCell>

									<TableCell>
										{getChargeAmount(pack.payments[0].charges, 'shp_cross_docking')}
									</TableCell>

									<TableCell>
										{getChargeAmount(pack.payments[0].charges, 'shp_fulfillment')}
									</TableCell>

									<TableCell>
										{getChargeAmount(pack.payments[0].charges, 'financing_fee')}
									</TableCell>

									<TableCell>
										{pack.payments[0].net_received_amount}
									</TableCell>

									<TableCell>
										{pack.payments[0].transaction_amount_refunded || ''}
									</TableCell>

									<TableCell rowSpan={pack.payments.length} style={{ borderLeft: '3px solid #eee', }}>
										{pack.invoice && `${pack.invoice.number} / ${pack.invoice.series}`}
									</TableCell>

									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && displayDate(pack.invoice.issued_date)}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && pack.invoice.status}
									</TableCell>

									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && displayDifal(pack.invoice)}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && getFiscalAmount(pack.invoice.fiscal_amounts, 'icms', 'vicms')}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && getFiscalAmount(pack.invoice.fiscal_amounts, 'ipi', 'vipi')}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && getFiscalAmount(pack.invoice.fiscal_amounts, 'pis', 'vpis')}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && getFiscalAmount(pack.invoice.fiscal_amounts, 'ibpt', 'vtottrib')}
									</TableCell>
									
									<TableCell rowSpan={pack.payments.length}>
										{pack.invoice && getFiscalAmount(pack.invoice.fiscal_amounts, 'cofins', 'vcofins')}
									</TableCell>
								
								</TableRow>

								{pack.payments.length > 1 && pack.payments.shift() && pack.payments.map((payment, payment_index) => <TableRow
									key={payment_index}
									sx={{
										'& > .MuiTableCell-root': {
											padding: '5px',
											fontSize: '11px'
										},
										'&.hovered': {
											backgroundColor: '#eee'
										}
									}}
									data-hoverrow={pack.pack_id}
									onMouseOver={mouse_over}
									onMouseOut={mouse_out}
								>
									
									<TableCell rowSpan={1} style={{ borderLeft: '3px solid #eee', }}>
										{payment.id}
									</TableCell>
									
									<TableCell rowSpan={1}>
										{displayPaymentStatus(payment.status)}
									</TableCell>
									
									<TableCell>
										{payment.total_paid_amount}
									</TableCell>

									<TableCell>
										{getChargeAmount(payment.charges, 'ml_fee')}
									</TableCell>

									<TableCell>
										{getChargeAmount(payment.charges, 'mp_fee')}
									</TableCell>

									<TableCell>
										{getChargeAmount(payment.charges, 'shp_cross_docking')}
									</TableCell>

									<TableCell>
										{getChargeAmount(payment.charges, 'shp_fulfillment')}
									</TableCell>

									<TableCell>
										{getChargeAmount(payment.charges, 'financing_fee')}
									</TableCell>

									<TableCell>
										{payment.net_received_amount}
									</TableCell>

									<TableCell>
										{payment.transaction_amount_refunded || ''}
									</TableCell>

								</TableRow>)}

								</>

							)}

						</TableBody>
					
					</Table>
				</TableContainer>

			</Grid>
		</>

	}

	else return <p>Carregando...</p>

}