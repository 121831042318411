import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'

export default function Search({value, handleSearch}) {

	return <Grid xs={12} lg={6}>
		<TextField
			id="search-field"
			label="Buscar (ID venda, Número NF, Cliente)"
			variant="outlined"
			onChange={e => handleSearch(e.target.value)}
			value={value}
			size='small'
			fullWidth
		/>
	</Grid>

}