import { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import TextInput from '../TextInput'
import submit_description from './submit_description'

import { useAuth } from '../../../../../AuthContext'

export default function DescriptionInput ({ initialValue, item_id }) {

	const {token} = useAuth()

	const [v, setV] = useState()
	const [initialV, setInitialV] = useState()
	const [changed, setChanged] = useState(false)

	// Sets initial values
	useEffect(() => {
		setV(initialValue)
		setInitialV(initialValue)
	}, [initialValue])

	// Wait for changes
	useEffect(() => {
		if (v !== initialV)
			setChanged(true)
		else
			setChanged(false)
	}, [v, initialV])

	const handleClickSubmit = async () => {
		
		const response = await submit_description (token, item_id, v)

		if (response.updated_items) {
			setInitialV(v)
			setChanged(false)
		} else {
			alert('Houve um erro ao atualizar.')
			console.log(response)
		}

	}

	const handleClickCancel = () => {
		setV(initialV)
		setChanged(false)
	}

	return <div style={{ marginTop: '25px' }}>
		
		{
			v !== null &&
			<TextInput
		    label='Descrição do anúncio'
		    value={v}
		    setValue={setV}
		    rows={10}
		  />
		}
	  
	  {
	  	changed &&
	  	<div style={{ display: 'flex', marginTop: '10px' }}>
				
				<Button onClick={handleClickCancel} >
					Cancelar
				</Button>

				<Button onClick={handleClickSubmit} variant='contained' sx={{ marginLeft: 'auto', order: 2 }} >
					Alterar
				</Button>

			</div>
		}

  </div>

}