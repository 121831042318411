function dateConverter(ISODate) {
  const date = new Date(ISODate);
  
  // Formata a data e hora para "dd/mm/aaaa hh:mm"
  const dia = String(date.getDate()).padStart(2, '0')
  const mes = String(date.getMonth() + 1).padStart(2, '0')
  const ano = date.getFullYear()
  
  const formatado = `${dia}/${mes}/${ano}`
  return formatado
  
}

export default function DateCell ({value}) {
  return dateConverter(value)
}