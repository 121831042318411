export function float_to_money(value) {

	return value.toLocaleString('pt-BR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

}

export default function MoneyCell ({ value }) {

  return float_to_money(value)

}