import { useState, useEffect } from 'react'
import TextField from '@mui/material/TextField'

export default function Search({handleSearch, initialSearch}) {

	// defined on each change
	const [value, setValue] = useState('')

	// defined initial value for textfield
	useEffect(() => { setValue(initialSearch) }, [initialSearch])
	
	// changes state and send change
	const handleChange = (e) => {
		e.preventDefault()
		setValue(e.target.value)
		handleSearch(e.target.value)
	}

	return <TextField
		id="search-field"
		label="Procurar no Concorrente"
		variant="outlined"
		size='small'
		fullWidth
		value={value}
		onChange={handleChange}
	/>

}