import Tooltip from '@mui/material/Tooltip'

const skuColumn = {
	field: 'sku',
  width: 100,
  sortable: false,
  disableColumnMenu: true,
  renderHeader: () => <Tooltip
    title='SKU da variação do anúncio no Mercado Livre.'
    arrow
  >
    <span>SKU</span>
  </Tooltip>
}

export default skuColumn