import DataGridStyles from './DataGridStyles'

import { DataGrid } from '@mui/x-data-grid'

import thumbnailColumn from './columns/thumbnailColumn'
import idColumn from './columns/idColumn'
import titleColumn from './columns/titleColumn'
import listingTypeColumn from './columns/listingTypeColumn'
import priceColumn from './columns/priceColumn'
import soldQuantityColumn from './columns/soldQuantityColumn'
import dateCreatedColumn from './columns/dateCreatedColumn'
import lastUpdatedColumn from './columns/lastUpdatedColumn'
import healthColumn from './columns/healthColumn'
import visitsColumn from './columns/visitsColumn'
import metricsColumn from './columns/metricsColumn'

export default function ConcurrentAnnsList({items}) {

	console.log(items)

	return items.length > 0 && <DataGrid
		rows={items}
		columns={[
			thumbnailColumn,
			idColumn,
			titleColumn,
			listingTypeColumn,
			priceColumn,
			dateCreatedColumn,
			//lastUpdatedColumn,
			//healthColumn,
			visitsColumn,
			soldQuantityColumn,
			metricsColumn,
		]}
		sx={DataGridStyles}
		columnHeaderHeight={30}
		rowHeight={75}
		disableRowSelectionOnClick
		hideFooterSelectedRowCount
		
		// Label of the "Sort" icon on sortable columns header
		localeText={{ columnHeaderSortIconLabel: 'Ordenar' }}
		
		initialState={{
			pagination: { paginationModel: { pageSize: 50 } },
		}}
	/>

}