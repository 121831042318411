import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useGridApiContext } from '@mui/x-data-grid'

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
import { green, red, purple } from '@mui/material/colors'

import { useAuth } from '../../../../../AuthContext'

import fetch_items_update from '../fetch_items_update'
import { mount_item } from '../mount_item'

export default function StatusChangeDialog({params}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [updating, setUpdating] = React.useState(false)
  
  const {token} = useAuth()
  const apiRef = useGridApiContext()

  const handleClickPause = async id => {
    setUpdating(true)
    const item_data = JSON.stringify({
      items: [{
        id: id,
        status: 'paused',
      }]
    })

    const response = await fetch_items_update(token, item_data)
    setUpdating(false)

    const mounted_items = response.items.map(item => mount_item(item))

    apiRef.current.updateRows(mounted_items)
  }

  const handleClickActivate = async id => {
    setUpdating(true)
    const item_data = JSON.stringify({
      items: [{
        id: id,
        status: 'active',
      }]
    })

    const response = await fetch_items_update(token, item_data)
    setUpdating(false)

    const mounted_items = response.map(item => mount_item(item))

    apiRef.current.updateRows(mounted_items)
  }

  const color = (opacity) => {
    if (params.row.status === 'active') return green[opacity]
    if (params.row.status === 'paused') return red[opacity]
    if (params.row.status === 'under_review') return purple[opacity]
  }

  const action = () => {
    if (params.row.status === 'active') handleClickPause(params.row.id)
    if (params.row.status === 'paused') handleClickActivate(params.row.id)
    handleClose()
  }
  
  const dialogTitle = () => params.row.quantity === 0 ? "Ops..." : "Você tem certeza?"

  const dialogText = () => {
    if (params.row.status === 'active') return 'Você tem certeza que deseja pausar o anúncio?'
    if (params.row.status === 'paused' && params.row.quantity !== 0) return 'Você tem certeza que deseja ativar o anúncio?'
    if (params.row.status === 'paused' && params.row.quantity === 0) return 'Você não pode ativar o anúncio pois ele está sem estoque.'
  }
  
  const AcceptButton = () => {
    if (params.row.status === 'active') return <Button onClick={action} autoFocus>Pausar Anúncio</Button>
    if (params.row.status === 'paused' && params.row.quantity !== 0) return <Button onClick={action} autoFocus>Ativar Anúncio</Button>
    if (params.row.status === 'paused' && params.row.quantity === 0) return null
  }

  return (
    <React.Fragment>
      <FiberManualRecordIcon
        sx={{
          cursor: 'pointer',
          color: color(updating ? 200 : 500),
          width: '21px',
          position: 'relative',
          top: '0'
        }}
        onClick={handleClickOpen}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogTitle()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogText()}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          {AcceptButton()}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}