import { createContext, useState, useContext, useEffect } from 'react'
import { useAuth } from './AuthContext'

import axios from './backend-config'

const PermissionsContext = createContext('')

async function fetch_permissions (token) {
  const response = await axios.get(`/user`, {
    headers: { Authorization: token }
  })
  return response.data.permissions
}

export const PermissionsProvider = (props) => {

  const { token } = useAuth()
  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    
    if (token !== null)
      fetch_permissions(token)
        .then(user_permissions => {
          setPermissions(user_permissions)
          setLoading(false)
        })

    else
      setLoading(false)
    
  }, [token])

  const has_permission = (permission) => permissions.includes(permission)

  return loading
  ? 'Loading Permissions...'
  : <PermissionsContext.Provider value={{ permissions, has_permission }}>
  	{props.children}
  </PermissionsContext.Provider>

}

// Um hook para usar o contexto nos componentes
export const usePermissions = () => useContext(PermissionsContext)