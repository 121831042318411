import {useState} from 'react'

import Grid from '@mui/material/Unstable_Grid2'

import FilterSeller from './FilterSeller'
import Sort from './Sort'
import Table from './Table'

export default function AnnsList ({items}) {

  const [sort, setSort] = useState('relevant-first')
  const [selectedSeller, setSellectecSeller] = useState('all')

  const sorted_items = () => {

    if (sort === 'more-visited-first') {
      return items.slice().sort((a, b) => parseInt(b.metrics.total_visits) - parseInt(a.metrics.total_visits))
    }

    if (sort === 'more-visited-first-7d') {
      return items.slice().sort((a, b) => parseInt(b.metrics.visits_7) - parseInt(a.metrics.visits_7))
    }

    if (sort === 'more-visited-first-14d') {
      return items.slice().sort((a, b) => parseInt(b.metrics.visits_14) - parseInt(a.metrics.visits_14))
    }

    if (sort === 'more-visited-first-30d') {
      return items.slice().sort((a, b) => parseInt(b.metrics.visits_30) - parseInt(a.metrics.visits_30))
    }

    if (sort === 'newer-first') {
      return items.slice().sort((a, b) => new Date(b.date_created) - new Date(a.date_created))
    }

    return items
  }

  return <>

    <Grid xs={4}>
      <FilterSeller
        items={items}
        onSellerSelect={setSellectecSeller}
      />
    </Grid>

    <Grid xs={4}>
      <Sort value={sort} onSortSellect={setSort} />
    </Grid>

    <Grid xs={12}>
      <Table
        items={selectedSeller === 'all' ? sorted_items() : sorted_items().filter(item => item.seller_nickname === selectedSeller)}
      />
    </Grid>

  </>

}