import MeliTipsMenu from '../MeliTipsMenu'

const thumbnailColumn = {
  field: 'thumbnail',
  headerName: '',
  width: 75,
  padding:0,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <MeliTipsMenu
    id={params.row.id}
    title={<img style={{
        width: 75,
        height: 75,
        marginTop: '3px',
      }} src={params.row.thumbnail} />}
    mine={params.row.isMine}
  />
}

export default thumbnailColumn