import { useState, useEffect } from 'react'

import Tooltip from '@mui/material/Tooltip'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import fetch_category_attributes from './fetch_category_attributes'

import StringInput from './fields/StringInput'
import ListSelect from './fields/ListSelect'
import NumberUnitSelect from './fields/NumberUnitSelect'

export default function CategoryAttributes ({category_id, item_attributes, item_id}) {

	const [attributes, setAttributes] = useState([])

	const getCategoryAttributes = async (category_id) => {
    const response = await fetch_category_attributes(category_id)
    return response
  }

	useEffect (() => {
		if (category_id){
			getCategoryAttributes(category_id)
			.then(attrs => setAttributes(attrs))
		}
	}, [category_id])

	return <>
		<p>Atributos:</p>
		{attributes.map(attribute => {
			
			// Conbforme a documentação, atributos read_only
			// não podem ser carregados nem modificados por vendedores,
			// sendo de uso exclusivo interno do mercado livre
			if (attribute.tags.read_only !== true) {

				const item_attribute = item_attributes.find(item_attribute => item_attribute.id === attribute.id)

		    if (attribute.value_type === 'string' || attribute.value_type === 'number')
		    	return <StringInput
		    		key={attribute.id}
		    		attribute={attribute}
		    		item_id={item_id}
		    		item_value={item_attribute ? item_attribute.value_name : ''}
		    	/>

		    if (attribute.value_type === 'boolean' || attribute.value_type === 'list')
		    	return <ListSelect
		    		key={attribute.id}
		    		attribute={attribute}
		    		item_id={item_id}
		    		item_value={
		    			item_attribute
		    			? item_attribute.value_id !== '-1' ? item_attribute.value_id : null
		    			: ''
		    		}
		    	/>

				if (attribute.value_type === 'number_unit') {
					return <NumberUnitSelect
		    		key={attribute.id}
		    		attribute={attribute}
		    		item_id={item_id}
		    		item_value={
		    			item_attribute
		    			? item_attribute.values[0].struct ? item_attribute.values[0].struct.number : null
		    			: ''
		    		}
						item_unit={
							item_attribute
		    			? item_attribute.values[0].struct ? item_attribute.values[0].struct.unit : null
		    			: attribute.default_unit
						}
		    	/>
		    }
				
			}

	  })}
	</>

}