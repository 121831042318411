import Link from '@mui/material/Link'

import { useNavigate } from 'react-router-dom'

function Tips ({ title, text, route }) {

	const navigate = useNavigate()

	return <p
		sx={{
			display: 'block',
			padding: '5px 0',
  		margin: '5px 0',
  		textDecoration: 'none',
  		color: '#222',

  		'&:hover': {
  			textDecoration: 'underline',
  		}
		}}
		href={route}
	>
		<strong>{title}</strong><br/>
		{text}
	</p> 

}

export default Tips