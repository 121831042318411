import Link from '@mui/material/Link';

import { useNavigate } from 'react-router-dom'

export function RegisterLink (){

	const navigate = useNavigate()

	return <Link
		href="#"
		variant="body2"
		onClick={() => navigate('/register')}
	>
		Não tem uma conta? Cadastre-se
	</Link>

}

export function PasswordRecoverLink () {

	const navigate = useNavigate()

	return <Link
		href="#"
		variant="body2"
		onClick={() => navigate('/password-recover')}
	>
		Esqueceu a senha?
	</Link>

}

export function LoginLink () {

	const navigate = useNavigate()

	return <Link
		href="#"
		variant="body2"
		onClick={() => navigate('/login')}
	>
    Já tem uma conta? Entre aqui
  </Link>

}