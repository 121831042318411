import Tooltip from '@mui/material/Tooltip'

const visitsColumn = {
	field: 'visits',
	width: 55,
	type: 'number',
	disableColumnMenu: true,
	sortable: true,
	renderHeader: () => (
    <Tooltip
		  title='Número de visitas no anúncio no Mercado Livre.'
		  arrow
		>
		  <span>Visitas</span>
		</Tooltip>
  ),
}

export default visitsColumn