export function money_to_float(value) {

	value = value.toString()
	value = value.replace('.', '')
	value = value.replace(',', '.')
	return parseFloat(value)
	
}

export function float_to_money(value) {

	return value.toLocaleString('pt-BR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

}

export function mount_item (item) {

	/*
	const net = item.price - (item.listing_cost.value + item.listing_cost.fixed) - item.shipping_cost
	const net_money = float_to_money(net)
	*/

	const variations = item.variations !== null
	?	item.variations.map(variation => {
		return {
			...variation,
			changed: false
		}
	})
	: null

	console.log(item)

	return {
		...item,
		sku: (item.sku)
			? item.sku
			: (item.variations !== null ? item.variations[0].sku : ''),
		changed: false,
		variations: variations,
	}

}