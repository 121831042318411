import TextField from '@mui/material/TextField'

export default function Search({value, handleSearch}) {

	return <TextField
		id="search-field"
		label="Buscar"
		variant="outlined"
		onChange={e => handleSearch(e.target.value)}
		value={value}
		size='small'
		fullWidth
		sx={{
			fontSize: '12px'
		}}
	/>

}