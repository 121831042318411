import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'
import Shortcut from './Shortcut'
import Tips from './Tips'

import home_banner from './banner-teste.png'

export default function PagePanelHome (props) {

	return <Layout>
		<Container maxWidth="lg">
			<Box sx={{ flexGrow: 1 }}>
		    <Grid container spacing={2} style={{padding:'50px 0'}}>
		      
		      <Grid md={3} item>
		      	
		      	<Typography
		      		variant="h5"
		      		component="div"
		      		sx={{ flexGrow: 1 }}
		      	>
			        Atalhos
			      </Typography>

			      <Shortcut text='Ver todos os meus anúncios.' route='/panel/anns' />
			      <Shortcut text='Ver os detalhes de um anúncio.' route='/panel/ann-details' />
			      <Shortcut text='Ver os minhas vendas.' route='/panel/orders' />
			      <Shortcut text='Conciliar minhas vendas.' route='/panel/conciliation' />
			      <Shortcut text='Fazer uma super busca.' route='/panel/power-search' />
			      <Shortcut text='Acompanhar um concorrente.' route='/panel/concurrent' />
			      <Shortcut text='Configurações da minha conta.' route='/panel/profile' />
			      <Shortcut text='Configurações da minha loja.' route='/panel/store' />

		      </Grid>

		      <Grid md={9} container>
		      	{/*
		      		<Grid md={6} item>
		      			<img src={home_banner} style={{ width: '100%' }} />
			      	</Grid>

			      	<Grid md={6} item>
		      			<img src={home_banner} style={{ width: '100%' }} />
			      	</Grid>
				*/}

			      <Grid lg={12} item>
		      	
			      	<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1 }}
			      	>
				        Dicas Lyzard
				      </Typography>

				      <Tips
				      	title='Mais opções para os anúncios:'
				      	text='Você pode clicar com o botão direito em cima dos títulos dos anúnios para ver mais opções, como "Ver anúncio no Mercado Livre" ou "Editar anúncio na Lyzard"'
				      	route=''
				      />

				      <Tips
				      	title='Gerenciar Promoções de um anúncio:'
				      	text='Você pode clicar na etiqueta de promoção em cada anúncio para ir direto para a página da Central de Promoções deste anúncio.'
				      	route=''
				      />

			      </Grid>

		      </Grid>

		     </Grid>
		  </Box>
		</Container>
	</Layout>

}