import { useState, useEffect } from 'react'
import { useAuth } from '../../../AuthContext'
import { useNavigate } from "react-router-dom"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import Layout from '../../../layout/panel/Layout'

import Search from './Search'
import AnnsList from './AnnsList'
import fetch_items from './fetch_items'

import HasNotIntegration from '../HasNotIntegration'

function mount_metrics (metrics) {
	return {
		total_visits: metrics.total_visits,
		
		visits_7: metrics.visits
			? metrics.visits.slice(-7).reduce((total, item) => total + item.total, 0)
			: <Tooltip title='Não foi possível carregar esta informação neste momento.'>⚠️</Tooltip>,
		
		visits_14: metrics.visits
			? metrics.visits.slice(-14).reduce((total, item) => total + item.total, 0)
			: <Tooltip title='Não foi possível carregar esta informação neste momento.'>⚠️</Tooltip>,
		
		visits_30: metrics.visits
			? metrics.visits.slice(-30).reduce((total, item) => total + item.total, 0)
			: <Tooltip title='Não foi possível carregar esta informação neste momento.'>⚠️</Tooltip>,
		
		visits_45: metrics.visits
			? metrics.visits.slice(-45).reduce((total, item) => total + item.total, 0)
			: <Tooltip title='Não foi possível carregar esta informação neste momento.'>⚠️</Tooltip>,
	}
}

function mount_items (items) {
	return items.map(item => {
		return {
			...item,
			metrics: mount_metrics(item.metrics)
		}
	})
}

function PageAnns () {

	const navigate = useNavigate()
	const { token } = useAuth()

	const [items, setItems] = useState([])
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const [search, setSearch] = useState('')
	const [initialSearch, setInitialSearch] = useState('')

	// Realiza a busca pelos anúncios
	const fetch_search = async (search) => {
		setErrorMessage(false)
		setLoading(true)

		const response = search !== ''
			? await fetch_items(search, token)
			: []
		
		if (!response.error) setItems(response)
		else setErrorMessage(response.error)
		
		setLoading(false)
	}

	// Caso uma busca seja definida
	useEffect(() => {
		if (search) {
			fetch_search(search)
		
			const queryParams = new URLSearchParams(window.location.search)
			queryParams.set('search', search)
			navigate(`?${queryParams.toString()}`)
		}
	}, [search])

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		if (search_url_query) {
			setSearch(search_url_query)
			setInitialSearch(search_url_query)
		}
	}, [])

	return <Layout>
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={

		    	<Grid container spacing={2} style={{padding:'20px 0'}}>
	      	
		      	<Grid xs={6}>
							<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Super Busca
			      	</Typography>
							
							<Typography
			      		variant="p"
			      		component="div"
			      		sx={{ flexGrow: 1, mb: 1 }}
			      	>
			      		Faça uma busca como se estivesse no mercado livre.
			      	</Typography>
			      </Grid>

			      <Grid xs={6}>
							<Search
								handleSearch={setSearch}
								initialSearch={initialSearch}
							/>
			      </Grid>

						{errorMessage && <Grid lg={12}><p>{errorMessage}</p></Grid>}
						
						{!errorMessage && items.length > 0 && <Grid xs={12} container>
							<AnnsList items={mount_items(items)} />
			      </Grid>}

						{!errorMessage && loading && <Grid xs={12}>
							<p>Carregando</p>
			      </Grid>}
				  </Grid>

				}/>

		  </Box>
		</Container>
	</Layout>

}

export default PageAnns