import axios from '../../../backend-config'

async function fetch_devolutions (token, dateRange) {

    const response = await axios.get(`mercadolivre/returns?dateRange=${dateRange}`, {
      headers: { Authorization: token }
    })
    const data = response.data
    console.log('data', data)
    return data
  
}

export default fetch_devolutions