import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Configuration from './Configuration'

import { useAuth } from '../../../../../AuthContext'
import axios from '../../../../../backend-config'

async function update(token, label, value) {
    const response = await axios.post(`slack/configuration`, {
        notification_label: label,
        slack_channel_id: value,
    }, {
        headers: { Authorization: token }
    })
    const data = response.data
    return data
}

function Configurations () {

    const { token } = useAuth()

    const configs = [
        {
            notification_label: 'delivered_orders',
            title: 'Pedidos Entregues',
            description: 'Receba notificações sempre que uma venda for entregue.',
            handleSubmit: async (value) => {
                await update(token, 'delivered_orders', value)
            }
        },
        {
            notification_label: 'new_orders',
            title: 'Novos Pedidos',
            description: 'Receba notificações sempre que fizer uma nova venda.',
            handleSubmit: async (value) => {
                await update(token, 'new_orders', value)
            }
        },
        {
            notification_label: 'new_returns',
            title: 'Novas Devoluções',
            description: 'Receba notificações sempre que uma nova devolução for aberta.',
            handleSubmit: async (value) => {
                await update(token, 'new_returns', value)
            }
        },
    ]

    return <Box sx={{ width: '100%', borderTop: '3px solid #eee', paddingTop: 2 }}>
        <Stack spacing={2}>
            {configs.map((config, index) => <Configuration
                key={index}
                config={config}
            />)}
        </Stack>
    </Box>
}

export default Configurations