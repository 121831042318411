import * as React from 'react'
import Tooltip from '@mui/material/Tooltip'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import Slide from '@mui/material/Slide'
import { DataGrid, useGridApiContext } from '@mui/x-data-grid'
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed'
import Alert from '@mui/material/Alert'

import CustomToolbar from './CustomToolbar'
import skuColumn from './columns/skuColumn'
import listingTypeColumn from './columns/listingTypeColumn'
import priceColumn from './columns/priceColumn'
import listingCostColumn from './columns/listingCostColumn'
import shippingCostColumn from './columns/shippingCostColumn'
import netColumn from './columns/netColumn'
import quantityColumn from './columns/quantityColumn'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export default function VariationsDialog({params}) {
  const apiRef = useGridApiContext()
  
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const rows = params.row.variations.map(variation => {
    return {
      id: variation.id,
      sku: variation.sku,
      quantity: variation.quantity,
      price: params.row.price,
      price_money: params.row.price_money,
      listing_type: params.row.listing_type,
      listing_cost: params.row.listing_cost,
      listing_money: params.row.listing_money,
      shipping_cost: params.row.shipping_cost,
      shipping_money: params.row.shipping_money,
      net: params.row.net,
      net_money: params.row.net_money,
    }
  })

  return (
    <div>
      <Tooltip title='Variações do anúncio'>
        <DynamicFeedIcon
          onClick={handleClickOpen}
          sx={{ cursor: 'pointer', color: 'purple' }}
        />
      </Tooltip>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        size='md'
      >
        
        <DialogTitle>
          Variações do anúncio {params.row.title}
        </DialogTitle>

        <DialogContent>
          <Alert severity="warning" sx={{ mb: 2 }}>
            <strong>Atenção!</strong> As variações devem ter o mesmo preço. Caso contrário, haverá um erro e a alteração não ocorrerá corretamente.
          </Alert>

          <DataGrid
            rows={rows}
            columns={[
              skuColumn,
              quantityColumn,
              listingTypeColumn,
              priceColumn,
              listingCostColumn,
              shippingCostColumn,
              netColumn
            ]}
            columnHeaderHeight={30}
            rowHeight={30}
            disableRowSelectionOnClick
            hideFooter
            slots={{ toolbar: CustomToolbar }}
            slotProps={{
              toolbar: {
                item_id: params.row.id,
                parent_grid_ref: apiRef
              }
            }}
            sx={{
              // Cell and Input sizing
              '& .MuiDataGrid-cell, .MuiInputBase-input': {
                fontSize: '11px',
                padding: '0 4px',
              },
              // Table Head sizing
              '& .MuiDataGrid-columnHeader': {
                fontSize: '13px',
                padding: '0 4px',
              },
            }}
            onCellEditStop={params => {

            }}
          />
        </DialogContent>

      </Dialog>
    </div>
  )
}