import { useState, useEffect } from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

function count_sellers (items) {
  const sellers = items.reduce((acc, item) => {
    const existingSeller = acc.find(seller => seller.nickname === item.seller_nickname)
    if (existingSeller) {
      existingSeller.count++
    } else {
      acc.push({ nickname: item.seller_nickname, count: 1 })
    }
    return acc
  }, [])

  return sellers
}

export default function ({ items, onSellerSelect  }) {

  const [selectedSeller, setSelectedSeller] = useState('all')
  const [sellers, setSellect] = useState([])

  useEffect(() => {
    setSellect(count_sellers(items))
  }, [])

  const handleSellerChange = (event) => {
    setSelectedSeller(event.target.value)
    onSellerSelect(event.target.value)
  }
  
  return (
    <FormControl fullWidth size='small'>
      <InputLabel id="seller-select-label">Filtrar Vendedor</InputLabel>
      <Select
        labelId="seller-select-label"
        id="seller-select"
        value={selectedSeller}
        onChange={handleSellerChange}
      >
        <MenuItem key='all' value='all'>
          Todos os Vendedores
        </MenuItem>
        {sellers.map(seller => 
          <MenuItem key={seller.nickname} value={seller.nickname}>
            {`${seller.nickname} (${seller.count} anúncios)`}
          </MenuItem>
        )}
      </Select>
    </FormControl>
  )

}