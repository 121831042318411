import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'

export default function OrdersMenu () {
	
	const navigate = useNavigate()

	return <>
		<Button
      onClick={() => navigate('/panel/orders')}
    >Vendas</Button>
	</>

}