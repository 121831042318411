import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import WebIcon from '@mui/icons-material/Web'
import TableCell from '@mui/material/TableCell'

const initialState = {
    mouseX: null,
    mouseY: null,
}

export default function Cell ({id, val}) {

    const [state, setState] = useState(initialState)
    
    const handleClick = (event) => {
        event.preventDefault()
        setState({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
    }
    const handleClose = () => setState(initialState)

    const handleEditClick = () => window.open(`https://www.mercadolivre.com.br/anuncios/${id}/modificar/`, '_blank')
    const handleEditonLyzardClick = () => window.open(`/panel/ann-details?item=${id}`)
    const handleSeeClick = () => window.open(`https://produto.mercadolivre.com.br/${id.substr(0, 3)}-${id.substr(3)}`, '_blank')

	return <>
        <TableCell onContextMenu={handleClick}>
            {val}
        </TableCell>
		<Menu
            open={state.mouseY !== null}
            onClose={handleClose}
            onClick={handleClose}
            sx={{ '& .MuiList-root.MuiList-padding': { padding: 0 } }}
            anchorReference="anchorPosition"
            anchorPosition={
                state.mouseY !== null && state.mouseX !== null
                    ? { top: state.mouseY, left: state.mouseX }
                    : undefined
            }
        >
            <MenuItem onClick={handleSeeClick}>
                <WebIcon sx={{marginRight: '10px'}} /> Ver no Mercado Livre
            </MenuItem>
            <MenuItem onClick={handleEditonLyzardClick}>
                <EditIcon sx={{marginRight: '10px'}} /> Editar na Lyzard
            </MenuItem>
            <MenuItem onClick={handleEditClick}>
                <EditIcon sx={{marginRight: '10px'}} /> Editar no Mercado Livre
            </MenuItem>
        </Menu>
	</>

}