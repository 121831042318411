import { useState, useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import TextInput from './TextInput'
import submit_attribute_alteration from '../submit_attribute_alteration'

import { useAuth } from '../../../../../../AuthContext'

export default function ListSelect ({attribute, item_value, item_id}) {
	
	const {token} = useAuth()

	const [value, setValue] = useState(item_value !== null ? item_value : '')
	const [initialValue, setInitialValue] = useState(item_value !== null ? item_value : '')
	const [changed, setChanged] = useState(false)
	const [notApplied, setNotApplied] = useState(item_value === null ? true : false)
	const [initialNotApplied, setInitialNotApplied] = useState(item_value === null ? true : false)

	useEffect(() => {
		if (value !== initialValue || notApplied !== initialNotApplied)
			setChanged(true)
		else
			setChanged(false)
	}, [value, notApplied])

	const handleClickSubmit = async () => {
		
		const response = notApplied
		// for Not Applied
		? await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_id: "-1",
				value_name: null
			})
		// for non Not Applied
		: await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_id: value,
			})

		if (response.item) {
			setInitialValue(value)
			setChanged(false)
		} else {
			alert('Ocorreu um erro ao enviar.')
		}

	}

	const handleClickCancel = () => {
		setValue(initialValue)
		setNotApplied(initialNotApplied)
		setChanged(false)
	}

	return <Grid xs={12} container spacing={2}>
		
		<Grid xs={12} style={{ marginBottom: '15px' }}>
			
			<FormControl fullWidth size="small" >
			  
			  {!notApplied && <>
				  <InputLabel id={attribute.id}>
				  	{attribute.name}
				  </InputLabel>

				  <Select
				    labelId={attribute.id}
				    id={attribute.id}
				    value={value}
				    label={`${attribute.name} (Relevância: ${attribute.relevance})`}
				    onChange={(e) => setValue(e.target.value)}
				  >
				  	{attribute.tags.fixed !== true && <MenuItem value=''>Selecionar</MenuItem>}
				    {attribute.values.map(value => <MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>)}
				  </Select>
			  </>}

			  {notApplied && <TextInput
					value='Não se aplica'
					label={attribute.name}
					disabled
				/>}
			  
			  {
					(attribute.tags.required !== true && attribute.tags.fixed !== true) &&
					<FormControlLabel
						control={<Checkbox
							checked={notApplied}
							onClick={() => {
								setChanged(true)
								setNotApplied(!notApplied)
							}}
						/>}
						label="Não se aplica"
					/>
				}

			  {changed && <div style={{ display: 'flex' }}>
				
					<Button onClick={handleClickCancel} >
						Cancelar
					</Button>

					<Button onClick={handleClickSubmit} variant='contained' sx={{ marginLeft: 'auto', order: 2 }} >
						Alterar
					</Button>

				</div>}

			</FormControl>
		</Grid>
	</Grid>

}