import Tooltip from '@mui/material/Tooltip'

const lastOrderDateColumn = {
  field: 'last_order_date',
  width: 90,
  type: 'date',
  disableColumnMenu: true,
  renderHeader: () => (
    <Tooltip
      title='Data da última venda do anúncio no Mercado Livre.'
      arrow
    >
      <span>Última Venda</span>
    </Tooltip>
  )
}

export default lastOrderDateColumn