import axios from '../../../../../../backend-config'

export default async function fetch_item_variations(token, id) {

  const response = await axios.get(`mercadolivre/items/${id}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    }
  })

  return response.data.items[0]

}