import { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit'
import WebIcon from '@mui/icons-material/Web'
import Tooltip from '@mui/material/Tooltip'

export default function MeliTipsMenu({id, title, tooltip, mine}){

	const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl)
  
  const handleClick = (event) => {
    event.preventDefault()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => setAnchorEl(null)

  const handleEditClick = () => window.open(`https://www.mercadolivre.com.br/anuncios/${id}/modificar/`, '_blank')
  const handleSeeClick = () => window.open(`https://produto.mercadolivre.com.br/${id.substr(0, 3)}-${id.substr(3)}`, '_blank')

	return <>

    {
      tooltip
      ? <Tooltip title={title}>
    		  <span onContextMenu={handleClick}>{title}</span>
        </Tooltip>
      : <span onContextMenu={handleClick}>{title}</span>
    }

		<Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      sx={{ '& .MuiList-root.MuiList-padding': { padding: 0 } }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <MenuItem onClick={handleSeeClick}>
        <WebIcon sx={{marginRight: '10px'}} /> Ver anúncio no Mercado Livre
      </MenuItem>

      {mine && <MenuItem onClick={handleEditClick}>
        <EditIcon sx={{marginRight: '10px'}} /> Editar anúncio no Mercado Livre
      </MenuItem>}
    </Menu>

	</>

}