import TextField from "@mui/material/TextField"

export default function TextInput ({label, value, setValue, rows, style, disabled}) {

  return <TextField
    id={label}
    label={label}
    variant="outlined"
    size='small'
    fullWidth
    value={value}
    onChange={e => setValue(e.target.value)}
    multiline={rows ? true : false}
    rows={rows}
    style={style}
    disabled={disabled}
  />

}