import { Navigate } from 'react-router-dom'
import { usePermissions } from './PermissionsContext'

const PermissionRoute = ({element, permission}) => {

	const { has_permission } = usePermissions()

	return !has_permission(permission)
	? <Navigate to="/panel" />
	: element

}

export default PermissionRoute