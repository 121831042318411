import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export default function Search({handleSearch, initialSearch}) {

	// Value definido a cada mudança
	const [value, setValue] = useState('')

	// Define o valor inicial do textfield, caso haja
	useEffect(() => { setValue(initialSearch) }, [initialSearch])

	// Search chamado somente no Submit
	const handleSubmit = (e) => {
		e.preventDefault()
		handleSearch(value)
	}

	return <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
		<TextField
			id="search-field"
			label="Buscar"
			variant="outlined"
			size='small'
			fullWidth
			value={value}
			onChange={e => setValue(e.target.value)}
		/>
	</Box>

}