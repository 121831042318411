import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../AuthContext'
import axios from '../../../../../backend-config'

async function fetch_slack_channels(token) {
    const response = await axios.get(`slack/channels?store_id=38`, {
        headers: { Authorization: token }
    })
    const data = response.data
    return data
}

function useSlackChannels () {

    const {token} = useAuth()
    const [slackChannels, setSlackChannels] = useState(null)

    useEffect(() => {
        fetch_slack_channels(token)
        .then(response => {
            setSlackChannels(response.data)
        })
    }, [])

    return {
        slackChannels
    }
    
}

export default useSlackChannels