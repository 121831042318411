import Link from '@mui/material/Link'

import { useNavigate } from 'react-router-dom'

function Shortcut ({ route, text }) {

	const navigate = useNavigate()

	return <Link
		sx={{
			display: 'block',
			padding: '5px 0',
  		margin: '5px 0',
  		textDecoration: 'none',
  		color: '#222',

  		'&:hover': {
  			textDecoration: 'underline',
  		}
		}}
		href={route}
	>
		{text}
	</Link>

}

export default Shortcut