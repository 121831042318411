import Tooltip from '@mui/material/Tooltip'

import { float_to_money, money_to_float } from '../../mount_item'

const handleChangePrice = (params) => {

	// Verifyy if data really changed
	if (params.value === params.row.price_money) return params.row

	const floatPrice = money_to_float(params.value)

  const listing_cost = (floatPrice * (params.row.listing_cost.percentage/100))
  const net          = (floatPrice - listing_cost - params.row.shipping_cost)

  const updated = {
		...params.row,
		price: floatPrice.toFixed(2),
		price_money: float_to_money(floatPrice),
		listing_money: float_to_money(listing_cost),
		net: net.toFixed(2),
		net_money: float_to_money(net),
		changed: true
	}
	updated.listing_cost.value = listing_cost.toFixed(2)

  return updated

}

const priceColumn = {
	field: 'price_money',
	width: 60,
	sortable: false,
	disableColumnMenu: true,
	editable: true,
	valueSetter: handleChangePrice,
	renderHeader: () => (
    <Tooltip
		  title='Preço do anúncio no Mercado Livre.'
		  arrow
		>
		  <span>Preço</span>
		</Tooltip>
  )
}

export default priceColumn