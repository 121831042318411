import MetricsDialog from '../MetricsDialog'

const metricsColumn = {
	field: 'metrics_actions',
	headerName: '',
	width: 10,
	type: 'string',
	disableColumnMenu: true,
	sortable: false,
	renderCell: (params) => <MetricsDialog item={params.row} />
}

export default metricsColumn