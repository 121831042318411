import { useState, useEffect } from 'react'
import { useAuth } from '../../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import fetch_orders from './fetch_orders'
import search_filter_mount from './search_filter_mount'
import Search from './Search'
import Row from './Row'

export default function OrdersReport () {

	const { token } = useAuth()

	const [orders, setOrders] = useState([])
	const [search, setSearch] = useState('')

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		
		if (search_url_query)
			setSearch(search_url_query)
	}, [])

	// On load page
	useEffect(() => {
		fetch_orders(token).then(orders => {
			setOrders(orders)
		})
	}, [token])

	if (orders.length > 0) {

		const mounted_orders = search_filter_mount(orders, search)

		return <>
			
			<Grid xs={12} lg={10} container spacing={3} sx={{ mt: 0, mb: 2 }}>
				<Search
					value={search}
					handleSearch={setSearch}
				/>
			</Grid>

			<Grid lg={12} container>
				
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow
							sx={{
								'& > .MuiTableCell-root': {
									padding: '5px',
									fontSize: '11px'
								},
							}}
						>
							<TableCell>Venda</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Nota Fiscal</TableCell>
							<TableCell>ID Produto</TableCell>
							<TableCell>Título Produto</TableCell>
							<TableCell>SKU</TableCell>
							
							<Tooltip title='Quantidade vendida de cada item' arrow>
								<TableCell>
									<span>Qtde</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Total vendido de cada item' arrow>
								<TableCell>
									<span>Total do Item</span>
								</TableCell>
							</Tooltip>
							
							<Tooltip title='Resultado financeiro da venda, resultado dos produtos mais valor adicionado menos custos de frete e tarifas' arrow>
								<TableCell>
									<span>Total Venda</span>
								</TableCell>
							</Tooltip>

						</TableRow>
					</TableHead>
					<TableBody>
						{mounted_orders.map((order) => <Row key={order.id} order={order} />)}
					</TableBody>
				</Table>

			</Grid>
		</>

	}

}