import { useState } from 'react'
import { useGridApiContext } from '@mui/x-data-grid'

import Tooltip from '@mui/material/Tooltip'
import ButtonGroup from '@mui/material/ButtonGroup'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import OutboundIcon from '@mui/icons-material/Outbound'

import { green, red, blue } from '@mui/material/colors'

import { useAuth } from '../../../../../AuthContext'

import fetch_items_update from '../fetch_items_update'
import fetch_item from '../fetch_item'
import { mount_item } from '../mount_item'

function UpdateButton({params}) {

	const [updating, setUpdating] = useState(false)
	
	const {token} = useAuth()
	const apiRef = useGridApiContext()

	const handleUpdateAlterations = async (id, status, price, quantity) => {
		
		setUpdating(true)
		const item_data = JSON.stringify({
			items: [{
				id: id,
				status: status,
				price: parseFloat(price),
				
				// Don't send quantity when have variations.
				// Variations quantity should be edited individually
				quantity: params.row.variations === null ? parseInt(quantity) : null
			}]
		})

		const response = await fetch_items_update(token, item_data)
		const mounted_items = response.map(item => mount_item(item))

		apiRef.current.updateRows(mounted_items)
		setUpdating(false)

	}

	return <Tooltip
		title="Enviar alterações para MercadoLivre."
		variant="solid"
		placement="bottom-start"
    arrow
	>
		{
			updating
			? <OutboundIcon
					sx={{
						color: blue[200],
						width: '20px',
						position: 'relative',
						top: '2px'
					}}
				/>
			: <OutboundIcon
					sx={{
						color: blue[500],
						width: '20px',
						position: 'relative',
						top: '2px'
					}}
					onClick={() => handleUpdateAlterations(params.row.id, params.row.status, params.row.price, params.row.quantity)}
				/>
		}
	</Tooltip>

}

function CancelButton({params}) {
	
	const [canceling, setCanceling] = useState(false)
	
	const {token} = useAuth()
	const apiRef = useGridApiContext()

	const handleCancelAlterations = async () => {
		
		setCanceling(true)
		const response = await fetch_item(token, params.row.id)
		const mounted_items = response.items.map(item => mount_item(item))
		setCanceling(false)

		apiRef.current.updateRows(mounted_items)

	}

	return <Tooltip
		title="Cancelar alterações deste anúncio."
		variant="solid"
		placement="bottom-start"
    arrow
	>
		{
			canceling
			? <CancelIcon
				sx={{
					color: red[200],
					width: '20px',
					position: 'relative',
					top: '2px'
				}}
			/>
			: <CancelIcon
				sx={{
					color: red[500],
					width: '20px',
					position: 'relative',
					top: '2px'
				}}
				onClick={() => handleCancelAlterations()}
			/>
		}
	</Tooltip>

}

function UpdatedButton({params}) {

	return <Tooltip
		title="Este anúncio está atualizado."
		variant="solid"
		placement="bottom-start"
    arrow
	>
		<CheckCircleIcon
			sx={{
				color: green[params.row.status === 'active' ? 500 : 300],
				width: '20px',
				position: 'relative',
				top: '2px'
			}}
		/>
	</Tooltip>

}

const updateActionsColumn = {
	field: 'update_actions',
	type: 'update_actions',
	headerName: '',
	width: 5,
	cellClassName: 'update_actions',
	disableColumnMenu: true,
	sortable: false,
	renderCell: (params) => {
		return <ButtonGroup aria-label="button group">
			{params.row.changed && <>
				<CancelButton params={params} />
				<UpdateButton params={params} />
			</>}

			{!params.row.changed && <UpdatedButton params={params} />}

		</ ButtonGroup>
	},
}

export default updateActionsColumn