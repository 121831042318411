import { mount_item } from './mount_item'

export default function search_filter_mount (items, search, filter) {

	const mounted_items = []

	items.forEach(item => {

		let matched_filter = false
			
		if (filter === 'all')
			matched_filter = true

		else if (filter === 'statusActive')
			matched_filter = item.status === 'active'

		else if (filter === 'statusPaused')
			matched_filter = item.status === 'paused'

		else if (filter === 'statusUnderReview')
			matched_filter = item.status === 'under_review'

		else if (filter === 'withFreeShipping')
			matched_filter = item.isFreeShipping === true

		else if (filter === 'withoutFreeShipping')
			matched_filter = item.isFreeShipping === false

		else if (filter === 'withFull')
			matched_filter = item.isFull === true

		else if (filter === 'withoutFull')
			matched_filter = item.isFull === false

		else if (filter === 'withQuantity')
			matched_filter = item.quantity !== 0

		else if (filter === 'withoutQuantity')
			matched_filter = item.quantity === 0

		else if (filter === 'withOrders')
			matched_filter = item.sold_quantity !== 0

		else if (filter === 'withoutOrders')
			matched_filter = item.sold_quantity === 0

		else if (filter === 'withVisits')
			matched_filter = item.visits !== 0

		else if (filter === 'withoutVisits')
			matched_filter = item.visits === 0

		else if (filter === 'withPromotion')
			matched_filter = item.active_promotions === true

		else if (filter === 'withoutPromotion')
			matched_filter = item.active_promotions === false
		
		else if (filter === 'withoutThumbnailQuality')
			matched_filter = item.tags.filter(tag => tag === "good_quality_thumbnail").length <= 0

		else if (filter === 'withoutPictureQuality')
			matched_filter = item.tags.filter(tag => tag === "good_quality_picture").length <= 0

		else if (filter === 'withIncompleteTechnicalSpecs')
			matched_filter = item.tags.filter(tag => tag === "incomplete_technical_specs").length > 0

		else if (filter === 'withPoorQualityThumbnail')
			matched_filter = item.tags.filter(tag => tag === "poor_quality_thumbnail").length > 0

		else if (filter === 'withManufacturingTime')
			matched_filter = item.manufacturing_time !== 0

		else if (filter === 'withoutManufacturingTime')
			matched_filter = item.manufacturing_time === 0

		// Hide all 'closed' items
		if (item.status === 'closed')
			matched_filter = false

		if (matched_filter) {

			const search_array = search.includes("|") ? search.split("|") : [search]
			
			function find_in_variations (string, variations) {
				if (variations === null) return false
				
				for (let i = 0; i < variations.length; i++) {
					if (variations[i].sku.includes(string.toLowerCase())) {
						return true
					}
				}
				return false
			}

			function find_title_or_sku (string, item) {
				const searchStrings = string.split(" ")
		    return searchStrings.every(searchString => {
		    	
		    	if (searchString.startsWith('!')) {
			      const termToExclude = searchString.substring(1); // Remove o caractere '!' para obter o termo de busca a ser excluído
			      return !(
			        item.title.toLowerCase().includes(termToExclude.toLowerCase()) ||
			        item.sku.toLowerCase().includes(termToExclude.toLowerCase()) ||
			        find_in_variations(termToExclude, item.variations)
			      )
			    } else {
			      return (
			        item.title.toLowerCase().includes(searchString.toLowerCase()) ||
			        item.sku.toLowerCase().includes(searchString.toLowerCase()) ||
			        find_in_variations(searchString, item.variations)
			      )
			    }
			    
		    })
			}

			function find_arraystrings_in_item (search_array, item) {
				return search_array.some(search_string => find_title_or_sku(search_string, item))
			}

			const matched_search = find_arraystrings_in_item(search_array, item)

			if (matched_search) mounted_items.push(mount_item(item))
		}

	})

	return mounted_items
}