import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../PermissionsContext'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { useAuth } from '../../AuthContext'

export default function AccountMenu () {
	
	const [anchorEl, setAnchorEl] = useState(null)
	const navigate = useNavigate()
	const {  logout } = useAuth()
  const { has_permission } = usePermissions()

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

	return <>
		<Button
      onClick={handleMenu}
    >Preferências</Button>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
		    style: {
		      width: 150,
		    },
		  }}
    >
      
      {has_permission('access_profile') && <MenuItem onClick={() => navigate('/panel/profile')}>Minha Conta</MenuItem>}
      {has_permission('access_store') && <MenuItem onClick={() => navigate('/panel/store')}>Minha Loja</MenuItem>}
      <MenuItem onClick={logout}>Sair</MenuItem>
    </Menu>
	</>

}