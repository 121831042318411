import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import Tooltip from '@mui/material/Tooltip'

import DialogContent from '@mui/material/DialogContent';

// Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import { purple, grey } from '@mui/material/colors'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PromotionsDialog(props) {
  
  const [open, setOpen] = React.useState(false)
  const { id, title, active_promotions } = props.item

  React.useEffect(() => {
    if (open) {
      console.log(`fetch promotions for ${id}`)
    }
  }, [open, id])

  const handleClose = () => setOpen(false)

  return (
    <>
      <Tooltip
        title={(active_promotions ? 'Existem Promoções ativas para este anúncio.' : 'Não existem Promoções ativas para este anúncio.')+' Clique para gerenciar promoções.'}
        variant="solid"
        placement="bottom-start"
        arrow
      >
        <LocalOfferIcon
          //onClick={() => setOpen(true)}
          onClick={() => window.open(`https://www.mercadolivre.com.br/anuncios/lista/promos?page=1&search=${id.substr(3)}`, '_blank')}
          sx={{ cursor: 'pointer', color: (active_promotions ? purple[500] : grey[500]), width: '20px', position: 'relative', top: '2px' }}
        />
      </Tooltip>
      
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: purple[500] }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        
        <DialogContent>
          A listagem de promoções está em desenvolvimento
        </DialogContent>

      </Dialog>
    </>
  );
}