import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'
import AnnsTable from './AnnsTable'

import HasNotIntegration from '../HasNotIntegration'

function PageAnns_Metrics () {

	return <Layout>
		
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={
		    	<Grid container spacing={2} style={{padding:'20px 0'}}>

			    	<Grid lg={12}>
			      	<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1 }}
			      	>
			      		Anúncios {'>'} Métricas
			      	</Typography>
			      </Grid>

						<Grid lg={12} container>
							<AnnsTable />
			      </Grid>

				  </Grid>
				 } />

		  </Box>
		</Container>
	</Layout>

}

export default PageAnns_Metrics