import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import Layout from '../../../layout/panel/Layout'

import StoreForm from './StoreForm'
import MeliIntegrationForm from './MeliIntegrationForm'
import SlackIntegrationForm from './SlackIntegrationForm'

export default function PageStore (props) {

	return <Layout>
		<Container maxWidth="lg">
			<Box sx={{ flexGrow: 1 }}>
		    <Grid container spacing={2} style={{padding:'20px 0'}}>
		      
		      <Grid lg={4} container lgOffset={4}>
		      	
		      	<StoreForm />

		      	<MeliIntegrationForm />

		      </Grid>

		     </Grid>
		  </Box>
		</Container>
	</Layout>

}