import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../AuthContext'
import axios from '../../../../../backend-config'

async function fetch_slack_configurations(token) {
    const response = await axios.get(`slack/configurations?store_id=38`, {
        headers: { Authorization: token }
    })
    const data = response.data
    return data
}

function useSlackConfigurations () {

    const {token} = useAuth()
    const [slackConfigurations, setSlackConfigurations] = useState(null)

    useEffect(() => {
        fetch_slack_configurations(token)
        .then(response => {
            setSlackConfigurations(response.data)
        })
    }, [])

    return {
        slackConfigurations
    }

}

export default useSlackConfigurations