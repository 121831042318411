function float_to_money(value) {

	return value.toLocaleString('pt-BR', {
		minimumFractionDigits: 2,
		maximumFractionDigits: 2
	})

}

const priceColumn = {
	field: 'price',
	headerName: 'Preço',
	width: 60,
	sortable: true,
	disableColumnMenu: true,
	renderCell: params => float_to_money(params.row.price)
}

export default priceColumn