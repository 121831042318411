import { useState } from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom'

import Logo from '../ComponentAuthLogo'
import { LoginLink } from '../ComponentAuthLinks'
import ComponentCopyright from '../ComponentCopyright'

import register_api from './register_api'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PageRegister() {
  
  const navigate = useNavigate()

  const [errorMessage, setErrorMessage] = useState('')
  const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const [storenameError, setStorenameError] = useState(false)

  const resetFieldsErrors = () => {
    setErrorMessage('')
    setNameError(false)
    setEmailError(false)
    setPasswordError(false)
    setConfirmPasswordError(false)
    setStorenameError(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    
    const data = new FormData(event.currentTarget)

    register_api({
      name: data.get('name'),
      email: data.get('email'),
      password: data.get('password'),
      confirmpassword: data.get('confirm-password'),
      storename: data.get('store_name'),
    }, (data) => {
      
      resetFieldsErrors()

      if (data.error === 'missing_field') {
        if (data.field === 'name'){
          setNameError(true)
          setErrorMessage('Nome é obrigatorio.')
        }
        if (data.field === 'email') {
          setEmailError(true)
          setErrorMessage('E-mail é obrigatorio.')
        }
        if (data.field === 'password') {
          setPasswordError(true)
          setErrorMessage('Senha é obrigatoria.')
        }
        if (data.field === 'confirmpassword') {
          setConfirmPasswordError(true)
          setErrorMessage('Confirmar Senha é obrigatorio.')
        }
        if (data.field === 'storename') {
          setStorenameError(true)
          setErrorMessage('Nome da Loja é obrigatorio.')
        }
      } else if (data.error === 'password_mismatch') {
        setPasswordError(true)
        setConfirmPasswordError(true)
        setErrorMessage('As senhas não são iguais.')
      }
      else if (data.error === 'email_already_exists') {
        setEmailError(true)
        setErrorMessage('E-mail já cadastrado.')
      }
      else if (data.error === 'cannot_register_user') {
        setErrorMessage('Houve um prolbema ao registrar usuário.')
      }
      else {
        alert(`${data.user.name} cadastrado com sucesso. Bem vindo!`)
        navigate('/login')
      }
      
    })
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Typography component="p" variant="p" sx={{ mt:2, textAlign: 'center' }}>
            Lembre-se de usar o seu melhor e-mail.<br/>
            É por ele que toda a comunicação<br/>com a Lyzard vai acontecer.
          </Typography>

          {errorMessage !== '' && <Alert severity="error" sx={{ mt: 1 }}>{errorMessage}</Alert>}

          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Nome"
                  autoFocus
                  error={nameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name="store_name"
                  required
                  fullWidth
                  id="store_name"
                  label="Nome da Loja"
                  error={storenameError}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  error={emailError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Senha"
                  type="password"
                  id="password"
                  error={passwordError}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  required
                  fullWidth
                  name="confirm-password"
                  label="Confirmar Senha"
                  type="password"
                  id="confirm-password"
                  error={confirmPasswordError}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Registtrar
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <LoginLink />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ComponentCopyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}