import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../AuthContext'

import { useGridApiContext } from '@mui/x-data-grid'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import OutboundIcon from '@mui/icons-material/Outbound'

import fetch_items_update from '../fetch_items_update'
import { mount_item } from '../mount_item'

function UpdateMassiveButton ({rows}) {

	const apiRef = useGridApiContext()
	const { token } = useAuth()
	const [changed, setChanged] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => setChanged(rows.filter(row => row.changed === true)), [rows])

	const handleClick = async () => {
		setLoading(true)
		const itemsToUpdate = {
			items: changed.map(item => {
				return {
					id: item.id,
					quantity: item.quantity,
					price: item.price,
				}
			})
		}
		const response = await fetch_items_update(token, JSON.stringify(itemsToUpdate))
		const mounted_items = response.map(item => mount_item(item))
		mounted_items.forEach(mounted_item => apiRef.current.updateRows([mounted_item]))
		setLoading(false)
	}

	if (changed.length > 0) return <Tooltip title='Clique para subir todas as alterações pendentes.'>
		<Button
			variant="contained"
			onClick={handleClick}
			size='small'
			sx={{
				marginLeft: 'auto',
				marginBottom: '10px'
			}}
			disabled={loading}
		>
			<>
				<OutboundIcon sx={{height: '20px', marginRight: '5px'}} />
				Subir {changed.length} {changed.length === 1
					? 'alteração pendente'
					: 'alterações pendentes'}
			</>
		</Button>
	</Tooltip>

	if (!changed.length > 0) return <Button
			variant="contained"
			size='small'
			disabled
			sx={{
				marginLeft: 'auto',
				marginBottom: '10px'
			}}
		>
			Não há alterações pendentes.
		</Button>
}

export default UpdateMassiveButton