import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'

export default function ConciliationPaymentsMenu () {
	
	const navigate = useNavigate()

	return <>
		<Button
      onClick={() => navigate('/panel/conciliation/payments')}
    >Nova Conciliação</Button>
	</>

}