import { useState, useEffect } from 'react'
import useSlackConfigurations from '../hooks/useSlackConfigurations'
import ChangeChannel from './ChangeChannel'

import Box from '@mui/material/Box'

function Configuration ({ config }) {

    const { slackConfigurations } = useSlackConfigurations()

    const [value, setValue] = useState('')
    
    useEffect(() => {
        if (slackConfigurations) {
            const slackConfiguration = slackConfigurations.find(slackConfig => slackConfig.notification_label === config.notification_label)
            
            if (slackConfiguration !== undefined)
                setValue(slackConfiguration.slack_channel_id)
            
            else
                setValue('undefined')
        }
    }, [slackConfigurations])

    return <>
        
        <Box>
            <p style={{margin: 0}}>
                <strong>{config.title}</strong>: {config.description}
            </p>
        </Box>

        <Box>
            <ChangeChannel
                value={value}
                setValue={setValue}
                handleSave={config.handleSubmit}
            />
        </Box>
        
    </>

}

export default Configuration