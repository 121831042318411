import Tooltip from '@mui/material/Tooltip'
import VariationsDialog from '../VariationsDialog'

const variationsColumn = {
  field: 'variations',
  width: 5,
  headerName: '',
  disableColumnMenu: true,
  editable: false,
  sortable: false,
  renderCell: params => {
  	return params.row.variations !== null && 
  	<VariationsDialog params={params} />
  },
}

export default variationsColumn