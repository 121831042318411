import { useState, useEffect } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'

export default function SearchConcurrent({handleSearch, initialSearch}) {

	// defined on each change
	const [value, setValue] = useState('')

	// defines initial value for textfield
	useEffect(() => { setValue(initialSearch) }, [initialSearch])

	// calleed on Submit
	const handleSubmit = (e) => {
		e.preventDefault()
		handleSearch(value)
	}

	return <Box component="form" noValidate onSubmit={handleSubmit}>
		<TextField
			id="search-field"
			label="Nome do vendedor"
			variant="outlined"
			size='small'
			fullWidth
			value={value}
			onChange={e => setValue(e.target.value)}
		/>
	</Box>

}