import Tooltip from '@mui/material/Tooltip'

const handleChangeQuantity = (params) => {
  
  // Verifyy if data really changed
  if (params.value === params.row.quantity) return params.row

  const quantity = params.value > 0 ? params.value : 0

  const updated = {
    ...params.row,
    quantity: parseInt(quantity),
    changed: true
  }

  return updated
}

const quantityColumn = {
	field: 'quantity',
  width: 100,
  type: 'number',
  sortable: false,
  disableColumnMenu: true,
  editable: true,
  valueSetter: handleChangeQuantity,
  renderHeader: () => <Tooltip
    title='Estoque da variação do anúncio no Mercado Livre.'
    arrow
  >
    <span>Estoque</span>
  </Tooltip>
}

export default quantityColumn