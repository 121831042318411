import { useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import Skeleton from '@mui/material/Skeleton';

export default function LoadingAnnsSkeleton() {

  /*
  useEffect(() => {
    // When component goes in
    document.querySelector('body').style.overflowY = 'hidden'
    
    // When component goes out
    return () => {
      document.querySelector('body').style.overflowY = 'auto'
    }
  })
  */

  return <>
    <Grid lg={3}>
      <Skeleton variant="rounded" sx={{
        width: '100%',
        height: 40
      }} />
    </Grid>

    <Grid lg={2}>
      <Skeleton variant="rounded" sx={{
        width: '100%',
        height: 40
      }} />
    </Grid>

    <Grid lg={12}>
      <Skeleton variant="rounded" sx={{
        width: '100%',
        height: '30vh',
        overflowY: 'hidden'
      }} />
    </Grid>
  </>
}