import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'
import HasNotIntegration from '../HasNotIntegration'

import NotificationsList from './NotificationsList'

export default function PageConciliationPayments () {

	return <Layout>
		
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={

		    	<Grid container spacing={2} style={{padding:'20px 0'}}>
	      	
		      	    <Grid lg={12}>
						<Typography
                            variant="h5"
                            component="div"
                            sx={{ flexGrow: 1, mb: 1 }}
                        >
                            Notificações do Mercado Livre
                        </Typography>
			        </Grid>
                    
                    <NotificationsList />

				</Grid>

				}/>

		  </Box>
		</Container>
	</Layout>

}