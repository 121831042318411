import axios from '../../../../../../backend-config'

export default async function fetch_item_variations_update(token, item) {

  const response = await axios.put('mercadolivre/items/variations', item, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  })

  return response.data

}