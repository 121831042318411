import Tooltip from '@mui/material/Tooltip'
import MeliTipsMenu from '../MeliTipsMenu'

const idColumn = {
  field: 'id',
  width: 120,
  sortable: false,
  disableColumnMenu: true,
  renderHeader: () => <Tooltip
    title='ID do anúncio no Mercado Livre.'
    arrow
  >
    <span>ID</span>
  </Tooltip>,
  renderCell: (params) => <MeliTipsMenu id={params.row.id} title={params.row.id} />,
}

export default idColumn