import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'
import AnnsDataGrid from './AnnsDataGrid'

import HasNotIntegration from '../HasNotIntegration'

export default function PageAnns_Quantity () {

	return <Layout>
		
		<Container>
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={
					
		    	<Grid container spacing={2} style={{ paddingTop: '20px' }}>

			    	<Grid lg={12}>
			      	<Typography
			      		variant="h5"
			      		component="div"
			      		sx={{ flexGrow: 1 }}
			      	>
			      		Anúncios {'>'} Gerenciar Estoques
			      	</Typography>
			      </Grid>

						<Grid lg={12} container style={{ paddingBottom: 0 }}>
							<AnnsDataGrid />
			      </Grid>

				  </Grid>
				 } />

		  </Box>
		</Container>
	</Layout>

}