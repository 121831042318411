import { useState, useEffect } from 'react'
import { useAuth } from '../../../AuthContext'
import { useNavigate } from "react-router-dom"

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'

import Layout from '../../../layout/panel/Layout'

import Search from './Search'
import fetch_item from './fetch_item'
import AnnDetails from './AnnDetails'

import HasNotIntegration from '../HasNotIntegration'

export default function PageAnnDetails () {

	const navigate = useNavigate()
	const { token } = useAuth()

	const [item, setItem] = useState(null)
	const [loading, setLoading] = useState(false)
	const [errorMessage, setErrorMessage] = useState(null)

	const [search, setSearch] = useState('')
	const [initialSearch, setInitialSearch] = useState('')

	// Realiza a busca pelo anúncio
	async function fetch_search (search) {
		setErrorMessage(false)
		setLoading(true)

		const response = search !== ''
			? await fetch_item(search, token)
			: null
		
			if (!response.error) setItem(response.item)
		else setErrorMessage(response.error)
		
		setLoading(false)
	}

	// Caso uma busca seja definida
	useEffect(() => {
		// eslint-disable-next-line
		if (search) {
			fetch_search(search)
		
			const queryParams = new URLSearchParams(window.location.search)
			queryParams.set('item', search)
			navigate(`?${queryParams.toString()}`)
		}
	}, [search])

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('item')
		if (search_url_query) {
			setSearch(search_url_query)
			setInitialSearch(search_url_query)
		}
	}, [])

	return <Layout>
		<Container maxWidth="100vw">
			<Box sx={{ flexGrow: 1 }}>
		    
				<HasNotIntegration children={

					<Grid container spacing={2} style={{padding:'20px 0'}}>
		      	
						<Grid xs={12}>
							<Typography
								variant="h5"
								component="div"
								sx={{ flexGrow: 1, mb: 1 }}
							>
								Detalhes do Anúncio
							</Typography>

							<Typography
								variant="p"
								component="div"
								sx={{ flexGrow: 1, mb: 1 }}
							>
								Insira o Código MLB do anúncio para ver todas as informações dele.
							</Typography>
						</Grid>

						<Grid xs={3}>
							<Search
								handleSearch={setSearch}
								initialSearch={initialSearch}
							/>
						</Grid>

						{errorMessage && <Grid xs={12}><p>{errorMessage}</p></Grid>}

						{!errorMessage && <Grid xs={12}>
							{loading
								? <p>Carregando dados...</p>
								: <AnnDetails item={item} />
							}
			      		</Grid>}
				  </Grid>

				} />

		  </Box>
		</Container>
	</Layout>

}