import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'

import Layout from '../../../layout/panel/Layout'

import AccountForm from './AccountForm'
import ChangePasswordForm from './ChangePasswordForm'

export default function PageProfile (props) {

	return <Layout>
		<Container maxWidth="lg">
			<Box sx={{ flexGrow: 1 }}>
		    <Grid container spacing={2} style={{padding:'20px 0'}}>
		      
		      <Grid lg={4} container lgOffset={4}>
		      	
		      	<AccountForm />

	          <ChangePasswordForm />

		      </Grid>

		     </Grid>
		  </Box>
		</Container>
	</Layout>

}