import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

export default function TableHeader () {

  return <TableHead>
    <TableRow
      sx={{
        '& > .MuiTableCell-root': {
          padding: '0 5px',
          fontSize: '11px'
        },
      }}
    >
      <TableCell>
        #
      </TableCell>
      <TableCell />
      <TableCell />
      <TableCell />
      <TableCell align="right">Preço</TableCell>
    </TableRow>
  </TableHead>

}