import { useState, useEffect } from 'react'

import axios from '../../../../backend-config'

import Grid from '@mui/material/Unstable_Grid2'

import TitleInput from './TitleInput'
import DescriptionInput from './DescriptionInput'
import ManufacturingTimeInput from './ManufacturingTimeInput'
import CategoryAttributes from './CategoryAttributes'

import dateConverter from './dateConverter'

async function fetch_description (item_id) {
  const response = await axios(`https://api.mercadolibre.com/items/${item_id}/description`)
  return response.data.plain_text
}

export default function AnnDetails ({item}) {

  const [description, setDescription] = useState(null)

  const getDescription = async (item_id) => {
    const response = await fetch_description(item_id)
    return response
  }

  // On Load item
  useEffect(() => {
    if (item !== null) {
      getDescription(item.id).then(desc => {
        // To use on raw renders
        //setDescription(desc.replace(/\n/g, "<br />"))
        
        // to use on textarea
        setDescription(desc)
        //setTempDescription(desc)
      })
    }
  }, [item])

  if (item !== null)
  return <>
    
    <Grid xs={12} container spacing={2}>
      
      <Grid xs={6} lg={4}>
        {item.pictures.map(picture => <img key={picture.id} src={picture.url} alt={picture.url} width={'200px'} />)}
      </Grid>

      <Grid xs={6} lg={5}>
        <TitleInput
          initialValue={item.title}
          item_id={item.id}
        />
        
        <DescriptionInput
          initialValue={description}
          item_id={item.id}
        />

        <ManufacturingTimeInput
          initialValue={item.manufacturing_time}
          item_id={item.id}
        />
      </Grid>

      <Grid xs={4} lg={3}>
        <p>Criado em: {dateConverter(item.date_created)}</p>
        <p>Categoria: {item.category_id}</p>
        <p>
          {(item.listing_type === 'gold_special') && 'Anúncio Clássico'}
          {(item.listing_type === 'gold_pro') && 'Anúncio Premium'}
        </p>
        <p>Preço: {item.price}</p>
        <p>Tarifa do Mercado Livre: {item.listing_cost.value}</p>
        <p>Frete Grátis: {item.shipping_cost}</p>
        <p>Estoque: {item.quantity}</p>
        <p>Promoções Ativas: {item.acive_promotions ? 'Sim' : 'Não'}</p>

        <p>Quantidade Vendida: {item.sold_quantity}</p>
        <p>Visitas: {item.visits}</p>

        <p><strong>Tags:</strong></p>
        {item.tags.map(tag => <p key={item.id+tag}>{tag}</p>)}
      </Grid>
      
      <Grid xs={4} lg={4}>
        <CategoryAttributes
          category_id={item.category_id}
          item_attributes={item.attributes}
          item_id={item.id}
        />
      </Grid>

      <Grid xs={4} lg={4}>
        <p>Vendas:</p>
        {item.orders && item.orders.map(order => <p key={order.id}>{order.id} - {dateConverter(order.date_closed)} - {order.sold_quantity}</p>)}
      </Grid>
    </Grid>
  </>

}