import Tooltip from '@mui/material/Tooltip';

const listingTypeColumn = {
  field: 'listing_type_actions',
  type: 'listing_type_actions',
  headerName: '',
  width: 5,
  disableColumnMenu: true,
  sortable: false,
  cellClassName: 'listing_type_actions',
  renderCell: (params) => {

    let description
    let tootip_title
    let background
    
    // Classic
    if (params.row.listing_type === 'gold_special') {
      description = 'C'
      tootip_title = `Este é um anúncio Clássico`
      background = 'yellow'
    }

    // Premium
    if (params.row.listing_type === 'gold_pro') {
      description = 'P'
      tootip_title = `Este é um anúncio Premium`
      background = 'orange'
    }

    return <Tooltip
      title={tootip_title}
      placement="left"
      arrow
    >
      <span style={{
        background: background,
        borderRadius: '3px',
        fontSize: '11px',
        fontWeight: '700',
        padding: '0 4px' }}
      >
        {description}
      </span>
    </Tooltip>
  },
}

export default listingTypeColumn