import { useState } from 'react';
import { Navigate } from 'react-router-dom'

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import axios from '../../../backend-config'
import { useAuth } from '../../../AuthContext'

import Logo from '../ComponentAuthLogo'
import { RegisterLink, PasswordRecoverLink } from '../ComponentAuthLinks'
import ComponentCopyright from '../ComponentCopyright'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function SignInSide() {

	const {login, token} = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [message, setMessage] = useState(false)

  const handleSubmit = async (e) => {

    e.preventDefault()

    setEmailError(false)
    setPasswordError(false)
    setMessage(false)

    try {
      
      const response = await axios.post('user/login', { email, password }, {
        headers: { "Content-Type": "application/json" },
      })
      if (response.data.token) login(response.data.token)

    } catch (error) {
      
      const error_data = error.response.data

      if (error_data.error === 'missing_field') {
        if (error_data.field === 'email'){
          setEmailError(true)
          setMessage('Preencha o e-mail.')
        }
        if (error_data.field === 'password'){
          setPasswordError(true)
          setMessage('Preencha a senha.')
        }
      }
      else if (error_data.error === 'user_email_does_not_exist'){
        setMessage('E-mail ou senha inválidos. Tente novamente.')
      }
      else if (error_data.error === 'password_mismatch'){
        setMessage('E-mail ou senha inválidos. Tente novamente.')
      }

    }

  };

  return (token
  ? <Navigate to="/panel" />
  : <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            //backgroundImage: 'url(https://fac.img.pmdstatic.net/fit/https.3A.2F.2Fi.2Epmdstatic.2Enet.2Ffac.2F2021.2F08.2F03.2Fbf7d6121-2ec7-41d0-b98b-270f5f9df5ba.2Ejpeg/970x485/quality/80/crop-from/center/focus-point/594%2C260/tout-savoir-sur-le-cameleon.jpeg)',
            //backgroundImage: 'url(http://192.168.50.216:3000/teste.jpeg)',
            backgroundImage: 'url(https://lyzard.samuelfoltran.com/login-side.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'bottom center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              mt: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Logo />
            <Typography component="p" variant="p" sx={{ mt:2, textAlign: 'center' }}>
              Acesse sua conta
            </Typography>

            {message && <Alert severity="error" sx={{ mt: 1 }}>{message}</Alert>}

            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={e => setEmail(e.target.value)}
                error={emailError}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
                error={passwordError}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 2, mb: 2 }}
              >
                Entrar
              </Button>
              <Grid container>
                {/*
                <Grid item xs={12}>
                  <PasswordRecoverLink />
                </Grid>
                */}
                <Grid item xs={12}>
                  <RegisterLink />
                </Grid>
              </Grid>
              <ComponentCopyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  )
}