import float_to_money from './float_to_money'

function dateConverter(ISODate) {
  const date = new Date(ISODate);
  
  const dia = String(date.getDate()).padStart(2, '0')
  const mes = String(date.getMonth() + 1).padStart(2, '0')
  const ano = date.getFullYear()
  
  // 2015-07-01T00:00:00.000-00:00
  const formatado = `${dia}/${mes}/${ano}`
  return formatado
  
}

export default function mount_order (order) {
	
	const items = order.items.map(item => {
		return {
			id: item.id,
      title: item.title,
      sku: null,
      quantity: item.quantity,
      unit_price: float_to_money(item.unit_price),
      total_value: float_to_money(item.quantity * item.unit_price),
      sale_fee: float_to_money(item.sale_fee * item.quantity),
      net_value: float_to_money((item.quantity * item.unit_price) - (item.sale_fee * item.quantity)),
		}
	})

	const sum_item_total = item => item.unit_price * item.quantity
	const sum_item_taxes = item => item.sale_fee * item.quantity
	const sum_totals = (acumulator, actual) => acumulator + actual
	
	const total_items_revenue = order.items.map(sum_item_total).reduce(sum_totals, 0)
	const total_items_taxes = order.items.map(sum_item_taxes).reduce(sum_totals, 0)
	const total_items_results = total_items_revenue - total_items_taxes

	return {
		id: order.pack_id ? order.pack_id : order.orders[0],
		date_closed: dateConverter(order.date_closed),
		buyer: order.buyer,
		shipment: order.shipment,
		invoice_number: order.invoice ? order.invoice.number : null,
		invoice_recipient_name: order.invoice ? order.invoice.recipient.name : null,
		items: items,
		total_taxes: float_to_money(total_items_taxes),
		total_amount: float_to_money(total_items_results),
		mercadopago_payments: order.mercadopago_payments.map(payment => {
			let money_release_status_label = ''

			if (payment.money_release_status === 'pending') money_release_status_label = 'Pendente'
			if (payment.money_release_status === 'released') money_release_status_label = 'Liberado'

			return {
				...payment,
				money_release_status_label: money_release_status_label,
				money_release_date: dateConverter(payment.money_release_date),
				charges: payment.charges.map(charge => {
					let label = ''
					if (charge.name === 'shp_cross_docking') label = 'Custo de envio'
					if (charge.name === 'ml_fee') label = 'Gestão de venda'
					if (charge.name === 'mp_fee') label = 'Tarifa de venda'
					if (charge.name === 'mercadopago_fee') label = 'Tarifa Mercado Pago'
					if (charge.name === 'financing_fee') label = 'Tarifa de Parcelamento'
					
					return {
						...charge,
						label,
					}
				}),
			}
		}),
	}

}