import { useState, useEffect } from 'react'
import { useAuth } from '../../../AuthContext'

import fetch_devolutions from './fetch_devolutions'
import List from './List'

function useDevolutions (initialDateRange) {

  const { token } = useAuth()
    
  const [devolutions, setDevolutions] = useState(null)
  const [filter, setFilter] = useState('')
  const [status, setStatus] = useState('')
  const [filtered, setFiltered] = useState(null)
  const [dateRange, setDateRange] = useState(initialDateRange)

  useEffect(() => {
    fetch_devolutions(token, dateRange)
    .then(response => {
      setDevolutions(response)
      setFiltered(response)
      setFilter('')
      setStatus('')
    })
  }, [token, dateRange])

  useEffect(() => {
    
    if (devolutions && devolutions.length) {

      let filteredDevolutions = [...devolutions]

      if (status === '') {
        filteredDevolutions = devolutions
      } else if (status === 'closed') {
        filteredDevolutions = devolutions.filter(d => d.status === 'closed')
      } else if (status === 'oppened') {
        filteredDevolutions = devolutions.filter(d => d.status !== 'closed')
      }

      if (filter === '') {
        // Não faz nada, mantém o filtro de status aplicado
      } else if (filter === 'affected') {
        filteredDevolutions = filteredDevolutions.filter(d => d.reputation.affects_reputation === 'affected')
      } else if (filter === 'not_affected') {
        filteredDevolutions = filteredDevolutions.filter(d => d.reputation.affects_reputation === 'not_affected' || d.reputation.affects_reputation === 'not_applies')
      }

      setFiltered(filteredDevolutions)

    }

  }, [status, filter, devolutions])

  return {
    devolutions: filtered,
    List: () => List(filtered),
    setFilter,
    setStatus,
    setDateRange,
  }

}

export default useDevolutions