import { createContext, useState, useContext } from 'react'

const AuthContext = createContext('')

export const AuthProvider = (props) => {

  const [token, setToken] = useState(props.token)

  const login = (token) => {
    setToken(token)
    localStorage.setItem('token', token)
  }

  const logout = () => {
    setToken(null)
    localStorage.removeItem('token')
  }

  return <AuthContext.Provider value={{ token, login, logout }}>
  	{props.children}
  </AuthContext.Provider>

}

// Um hook para usar o contexto nos componentes
export const useAuth = () => useContext(AuthContext)