import { useState } from 'react'
import TextField from '@mui/material/TextField'

import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import OutboundIcon from '@mui/icons-material/Outbound'

import { green, red, blue } from '@mui/material/colors'

const buttonStyles = {
    width: '25px',
    position: 'relative',
    top: '5px'
}

const buttonGroupStyles = {
    marginRight: '3px'
}

export default function EditQuantity ({item}) {

    const [quantity, setQuantity] = useState(item.quantity)
    const [changed, setChanged] = useState(false)
    const [processing, setProcesing] = useState(false)

    const handleChange = (e) => {
        setChanged(true)
        setQuantity(e.target.value)
    }

    const handleCancel = () => {
        setQuantity(item.quantity)
        setChanged(false)
    }

    const handleUpdate = () => {
        
    }

    function UpdateButton() {

        return <Tooltip
            title="Enviar alterações para MercadoLivre."
            variant="solid"
            placement="bottom-start"
            arrow
        >
            {
                processing
                ? <OutboundIcon
                        sx={{
                            color: blue[200],
                            ...buttonStyles,
                            ...buttonGroupStyles,
                        }}
                    />
                : <OutboundIcon
                        sx={{
                            color: blue[500],
                            ...buttonStyles,
                            ...buttonGroupStyles,
                        }}
                        onClick={() => handleUpdate()}
                    />
            }
        </Tooltip>
    
    }

    function CancelButton() {
	
        return <Tooltip
            title="Cancelar alterações deste anúncio."
            variant="solid"
            placement="bottom-start"
            arrow
        >
            {
                processing
                ? <CancelIcon
                    sx={{
                        color: red[200],
                        ...buttonStyles,
                        ...buttonGroupStyles,
                    }}
                />
                : <CancelIcon
                    sx={{
                        color: red[500],
                        ...buttonStyles,
                        ...buttonGroupStyles,
                    }}
                    onClick={() => handleCancel()}
                />
            }
        </Tooltip>
    
    }
    
    function UpdatedButton() {
    
        return <Tooltip
            title="Este anúncio está atualizado."
            variant="solid"
            placement="bottom-start"
            arrow
        >
            <CheckCircleIcon
                sx={{
                    color: green[item.status === 'active' ? 500 : 300],
                    ...buttonStyles
                }}
            />
        </Tooltip>
    
    }

    return <>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flexGrow: '0', width: '60px', marginRight: '10px' }}>
            <TextField
                id="quantity-field"
                onChange={handleChange}
                value={quantity}
                size='small'
                fullWidth
                variant='standard'
                InputProps={{
                    disableUnderline: true,
                }}
                style={{ marginTop: '5px' }}
            />
        </div>

        <div style={{ flexGrow: '0', width: '50px' }}>
            {!changed ? <UpdatedButton />
                : <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CancelButton />
                    <UpdateButton />
                </div>}
        </div>
    </div>
    </>

}