import { useState, useEffect } from 'react'

import { useAuth } from '../../../AuthContext'
import axios from '../../../backend-config'

import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Alert from '@mui/material/Alert'

function ChangePasswordForm(){

	const [id, setId] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [error, setError] = useState(false)
	const [success, setSuccess] = useState(false)
	
	const { token } = useAuth()

	async function fetch_data() {
		const response = await axios.get(`user`, {
			headers: { Authorization: token },
		})
		setId(response.data.user_id)
	}

	useEffect(() => {
		fetch_data()
	}, [])

	async function handleSubmit () {
		if (password !== confirmPassword)
			setError('A senha e a confirmação de senha devem ser a mesma.')

		else {
			setError(false)

			const response = await axios.put(`user/password`, { id: id, password: password }, {
				headers: {
					Authorization: token,
					"Content-Type": "application/json",
				},
			})
			
			const data = response.data
			if (data.error) {
				if (data.error === 'invalid_password') setError('A senha é inválida.')
				if (data.error === 'cannot_update_user') setError('Não foi possível salvar a nova senha, pois houve um erro inesperado.')
			}

			else {
				setPassword('')
				setConfirmPassword('')
				setSuccess(true)
			}
			
		}
	}

	return <>
		<Grid xs={12} mt={2}>
  		<Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
        Alterar Senha
      </Typography>
      
      <p>Para alterar a senha, digite e confirme a nova senha desejada.</p>
  	</Grid>

		{error && <Grid xs={12}>
			<Alert severity="error">{error}</Alert>
  	</Grid>}

		{success && !error && <Grid xs={12}>
			<Alert severity="success">Senha alterada com sucesso.</Alert>
  	</Grid>}

  	<Grid xs={6}>
  		<TextField
  			label="Nova Senha"
  			variant="standard"
  			fullWidth
  			value={password}
  			onChange={(e) => setPassword(e.target.value)}
  		/>
  	</Grid>

  	<Grid xs={6}>
  		<TextField
  			label="Confirmar Nova Senha"
  			variant="standard"
  			fullWidth
  			value={confirmPassword}
  			onChange={(e) => setConfirmPassword(e.target.value)}
  		/>
  	</Grid>

  	<Grid xs={6} xsOffset={6}>
  		<Button
  			variant="contained"
  			fullWidth
				disabled={!password || !confirmPassword}
				onClick={handleSubmit}
  		>
  			Salvar
  		</Button>
  	</Grid>
	</>
}

export default ChangePasswordForm