import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

export default function Filters ({value, handleFilter}) {

  return <FormControl style={{ width: '100%' }}>
    <InputLabel id="demo-simple-select-helper-label" size="small">Filtrar</InputLabel>
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      value={value}
      label="Tipo"
      onChange={e => handleFilter(e.target.value)}
      size="small"
    >
      <MenuItem value='all' selected>Todos</MenuItem>
      <MenuItem value='statusActive'>Status: ativo</MenuItem>
      <MenuItem value='statusPaused'>Status: pausado</MenuItem>
      <MenuItem value='statusUnderReview'>Status: revisão</MenuItem>
      <MenuItem value='withFull'>Com FULL</MenuItem>
      <MenuItem value='withoutFull'>Sem FULL</MenuItem>
      <MenuItem value='withFreeShipping'>Com Frete Grátis</MenuItem>
      <MenuItem value='withoutFreeShipping'>Sem Frete Grátis</MenuItem>
      <MenuItem value='withQuantity'>Com Estoque</MenuItem>
      <MenuItem value='withoutQuantity'>Sem Estoque</MenuItem>
      <MenuItem value='withManufacturingTime'>Com Tempo de Disponibilidade</MenuItem>
      <MenuItem value='withoutManufacturingTime'>Sem Tempo de Disponibilidade</MenuItem>
    </Select>
  </FormControl>

}