import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"

import Grid from '@mui/material/Unstable_Grid2'
import { DataGrid } from '@mui/x-data-grid'

import { useAuth } from '../../../../AuthContext'
import fetch_items from './fetch_items'
import search_filter_mount from './search_filter_mount'

import LoadingAnnsSkeleton from './LoadingAnnsSkeleton'
import Filters from './Filters'
import Search from './Search'
import CustomToolbar from './CustomToolbar'

// Columns
import statusColumn from './columns/statusColumn'
import thumbnailColumn from './columns/thumbnailColumn'
import idColumn from './columns/idColumn'
import titleColumn from './columns/titleColumn'
import skuColumn from './columns/skuColumn'
import listingTypeColumn from './columns/listingTypeColumn'
import quantityColumn from './columns/quantityColumn'
import variationsColumn from './columns/variationsColumn'
import updateActionsColumn from './columns/updateActionsColumn'

import DataGridStyles from './DataGridStyles'

export default function AnnsDataGrid() {

	const navigate = useNavigate()
	const {token} = useAuth()

	const [items, setItems] = useState([])
	const [search, setSearch] = useState('')
	const [filter, setFilter] = useState('all')

	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		
		if (search_url_query)
			setSearch(search_url_query)
	}, [])

	// Caso uma busca seja definida
	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search)
		queryParams.set('search', search)
		// Se tem busca, adiciona o atributo, se não não
		navigate(search ? `?${queryParams.toString()}` : '')
	}, [search])
	
	useEffect(() => {
		fetch_items(token).then(response => setItems(response) )
	}, [token])

	if (items.length === 0) return <LoadingAnnsSkeleton />

	if (items.error) return <p>{items.error}</p>

	else if (items.items) {

		const mounted_items = search_filter_mount(items.items, search, filter)

		return <>
			<Grid xs={12} lg={3} item>
				<Search
					value={search}
					handleSearch={setSearch}
				/>
			</Grid>

			<Grid xs={12} lg={3} item>
				<Filters
					value={filter}
					handleFilter={setFilter}
				/>
			</Grid>

			<Grid lg={12} item>
				<DataGrid
					rows={mounted_items}
					columns={[
						statusColumn,
						thumbnailColumn,
						idColumn,
						titleColumn,
						//skuColumn,
						listingTypeColumn,
						quantityColumn,
						variationsColumn,
						updateActionsColumn,
					]}
					columnHeaderHeight={30}
					rowHeight={45}
					disableRowSelectionOnClick
					hideFooterSelectedRowCount
					sx={DataGridStyles}
					components={{ Toolbar: CustomToolbar }}

					// Prevents items with promotions to be editable.
					// This problems will be solved and them all the items will be editable
					isCellEditable={(params) => params.row.variations === null}
					
					// Label of the "Sort" icon on sortable columns header
					localeText={{ columnHeaderSortIconLabel: 'Ordenar' }}
					
					initialState={{
						sorting: { sortModel: [{ field: 'date_created', sort: 'desc' }], },
						pagination: { paginationModel: { pageSize: 25 } },
					}}
				/>
			</Grid>
		</>

	}
}