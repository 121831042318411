import Tooltip from '@mui/material/Tooltip'
import MeliTipsMenu from '../MeliTipsMenu'

const thumbnailColumn = {
  field: 'thumbnail',
  width: 55,
  sortable: false,
  disableColumnMenu: true,
  renderHeader: () => '',
  renderCell: (params) => <MeliTipsMenu id={params.row.id} title={
    <img style={{
      width: 40,
      height: 40,
      //padding: '5px',
      marginTop: '5px',
    }} src={params.row.thumbnail} />
  } />,
}

export default thumbnailColumn