function useMonths () {

    function getLast12Months() {
        const months = [];
        const currentDate = new Date();
        
        for (let i = 0; i < 12; i++) {
            const date = new Date(currentDate);
            date.setMonth(currentDate.getMonth() - i);
            const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
            const firstDayOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
            months.push({
                label: `${getMonthName(date.getMonth())} de ${date.getFullYear()}`,
                dateRange: `date_created:after:${formatDate(firstDayOfMonth)},before:${formatDate(firstDayOfNextMonth)}`
            });
        }
        
        return months;
    }
    
    function getMonthName(monthIndex) {
        const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];
        return monthNames[monthIndex];
    }
    
    function padZero(number) {
        return number < 10 ? '0' + number : number;
    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = padZero(date.getMonth() + 1);
        const day = padZero(date.getDate());
        return `${year}-${month}-${day}T00:00:00.000Z`;
    }

    return {
        getLast12Months
    }

}

export default useMonths