const DataGridStyles = {
	// Prevents extra scroll in table
	'& .MuiDataGrid-virtualScrollerContent': {
		width: 'auto !important'
	},
	// Row :hover effect
	'& .MuiDataGrid-row:hover': {
		backgroundColor: '#ddd',
	},
	// Cell and Input sizing
	'& .MuiDataGrid-cell, .MuiInputBase-input': {
		fontSize: '11px',
		padding: '0 4px',
	},
	// Table Head sizing
	'& .MuiDataGrid-columnHeader': {
		fontSize: '13px',
		padding: '0 4px',
	},
}

export default DataGridStyles