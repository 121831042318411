import Tooltip from '@mui/material/Tooltip'

const listingCostColumn = {
	field: 'listing_money',
	width: 60,
	sortable: false,
	disableColumnMenu: true,
	renderHeader: () => (
    <Tooltip
		  title='Comissão do Mercado Livre em caso de venda.'
		  arrow
		>
		  <span>Tarifa</span>
		</Tooltip>
  )
}

export default listingCostColumn