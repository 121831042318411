import MeliTipsMenu from '../MeliTipsMenu'

const idColumn = {
  field: 'id',
  headerName: 'ID',
  width: 100,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <MeliTipsMenu id={params.row.id} title={params.row.id} mine={params.row.isMine} />,
}

export default idColumn