import Tooltip from '@mui/material/Tooltip'

const dateCreatedColumn = {
	field: 'date_created',
	width: 90,
	type: 'date',
	disableColumnMenu: true,
	renderHeader: () => (
    <Tooltip
		  title='Data de criação do anúncio no Mercado Livre.'
		  arrow
		>
		  <span>Data Criação</span>
		</Tooltip>
  )
}

export default dateCreatedColumn