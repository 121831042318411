import TableRow from '@mui/material/TableRow'

import Cell from './Cell'
import StatusCell from './StatusCell'
import ListingTypeCell from './ListingTypeCell'
import DateCell from './DateCell'

import EditQuantity from './EditQuantity'

export default ({item}) => {

  return <TableRow
    sx={{
      '&:hover': {
        backgroundColor: '#f5f5f5'
      },
      '& > .MuiTableCell-root': {
        padding: '5px',
        fontSize: '11px'
      },
    }}
  >

    <Cell
      val={<StatusCell item={item} />}
      id={item.id}
    />

    <Cell
      val={
        <img
          style={{
            width: 40,
            height: 40,
            display: 'block',
          }}
          src={item.thumbnail}
        />
      }
      id={item.id}
    />

    <Cell
      val={<>
        {item.id}<br/>
        <ListingTypeCell item={item} /><br/>
        Criado em <DateCell value={item.date_created} />
      </>}
      id={item.id}
    />

    <Cell
      val={
        <>
          {item.title}<br/>
          <strong>
            {item.variations === null
              ? item.sku
              : item.variations.map(variation => variation.sku).join(' / ')
            }
          </strong>
        </>
      }
      id={item.id}
    />

    <Cell
      val={<EditQuantity item={item} />}
      id={item.id}
    />

  </TableRow>

}