const DataGridStyles = {
	// Prevents extra scroll in table
	'& .MuiDataGrid-virtualScrollerContent': {
		width: 'auto !important'
	},
	// Row :hover effect
	'& .MuiDataGrid-row:hover': {
		backgroundColor: '#ddd',
	},
	// Status Column width
	'& .status_actions, .MuiDataGrid-columnHeader[data-field=status_actions]': {
		minWidth: '30px !important',
		maxWidth: '30px !important',
		width: '30px !important',
	},
	// Listing Type Column width
	'& .listing_type_actions, .MuiDataGrid-columnHeader[data-field=listing_type_actions]': {
		minWidth: '80px !important',
		maxWidth: '80px !important',
		width: '80px !important',
	},
	// Status Column width
	'& .relist_actions, .MuiDataGrid-columnHeader[data-field=relist_actions]': {
		minWidth: '28px !important',
		maxWidth: '28px !important',
		width: '28px !important',
	},
	// Promotions Column width
	'& .MuiDataGrid-cell[data-field=promotions_actions], .MuiDataGrid-columnHeader[data-field=promotions_actions]': {
		minWidth: '28px !important',
		maxWidth: '28px !important',
		width: '28px !important',
	},
	// Variations Column width
	'& .MuiDataGrid-cell[data-field=variations], .MuiDataGrid-columnHeader[data-field=variations]': {
		minWidth: '28px !important',
		maxWidth: '28px !important',
		width: '28px !important',
	},
	// Metrics Column width
	'& .MuiDataGrid-cell[data-field=metrics_actions], .MuiDataGrid-columnHeader[data-field=metrics_actions]': {
		minWidth: '28px !important',
		maxWidth: '28px !important',
		width: '28px !important',
	},
	// Cell and Input sizing
	'& .MuiDataGrid-cell, .MuiInputBase-input': {
		fontSize: '11px',
		padding: '0 4px',
	},
	// Table Head sizing
	'& .MuiDataGrid-columnHeader': {
		fontSize: '13px',
		padding: '0 4px',
	},
}

export default DataGridStyles