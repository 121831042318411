const DataGridStyles = {
	'&': {
		maxHeight: '72vh'
	},
	
	// Table Border
	'&, & .MuiDataGrid-columnHeaders': {
		border: 'none',
	},
	// Prevents extra scroll in table
	'& .MuiDataGrid-virtualScrollerContent': {
		width: 'auto !important'
	},
	// Row :hover effect
	'& .MuiDataGrid-row:hover': {
		backgroundColor: '#f5f5f5',
	},
	// Status Column width
	'& .status_actions, .MuiDataGrid-columnHeader[data-field=status_actions]': {
		minWidth: '45px !important',
		maxWidth: '45px !important',
		width: '45px !important',
	},
	// Listing Type Column width
	'& .listing_type_actions, .MuiDataGrid-columnHeader[data-field=listing_type_actions]': {
		minWidth: '90px !important',
		maxWidth: '90px !important',
		width: '90px !important',
	},
	// Status Column width
	'& .relist_actions, .MuiDataGrid-columnHeader[data-field=relist_actions]': {
		minWidth: '40px !important',
		maxWidth: '40px !important',
		width: '40px !important',
	},
	// Variations Column width
	'& .MuiDataGrid-cell[data-field=variations], .MuiDataGrid-columnHeader[data-field=variations]': {
		minWidth: '40px !important',
		maxWidth: '40px !important',
		width: '40px !important',
	},
	// Cell and Input sizing
	'& .MuiDataGrid-cell, .MuiInputBase-input': {
		fontSize: '11px',
		padding: '0 4px',
	},
	// Table Head sizing
	'& .MuiDataGrid-columnHeader': {
		fontSize: '13px',
		padding: '0 4px',
	},
}

export default DataGridStyles