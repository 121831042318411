function dateConverter(ISODate) {
  const date = new Date(ISODate);
  
  // Formata a data e hora para "dd/mm/aaaa hh:mm"
  const dia = String(date.getDate()).padStart(2, '0')
  const mes = String(date.getMonth() + 1).padStart(2, '0')
  const ano = date.getFullYear()
  
  const formatado = `${dia}/${mes}/${ano}`
  return formatado
  
}

const dateCreatedColumn = {
  field: 'date_created',
  headerName: 'Data Criação',
  width: 100,
  sortable: true,
  disableColumnMenu: true,
  renderCell: params => dateConverter(params.row.date_created)
}

export default dateCreatedColumn