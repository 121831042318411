import { useState, useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import TextInput from './TextInput'
import submit_attribute_alteration from '../submit_attribute_alteration'

import { useAuth } from '../../../../../../AuthContext'

export default function StringInput ({attribute, item_value, item_id}) {

	const {token} = useAuth()

	const [value, setValue] = useState(item_value !== null ? item_value : '')
	const [initialValue, setInitialValue] = useState(item_value !== null ? item_value : '')
	const [changed, setChanged] = useState(false)
	const [notApplied, setNotApplied] = useState(item_value === null ? true : false)
	const [initialNotApplied, setInitialNotApplied] = useState(item_value === null ? true : false)

	useEffect(() => {
		if (value !== initialValue || notApplied !== initialNotApplied)
			setChanged(true)
		else
			setChanged(false)
	}, [value, notApplied])
	
	const handleClickSubmit = async () => {
		
		const response = notApplied
		// for Not Applied
		? await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_id: "-1",
				value_name: null
			})
		// for non Not Applied
		: await submit_attribute_alteration (token, item_id, {
				id: attribute.id,
				value_name: value
			})
		
		if (response.item) {
			setInitialValue(value)
			setChanged(false)
		} else {
			alert('Houve um erro ao atualizar.')
		}
		
	}

	const handleClickCancel = () => {
		setValue(initialValue)
		setNotApplied(initialNotApplied)
		setChanged(false)
	}

	return <Grid xs={12} container spacing={2}>
		
		<Grid xs={12} style={{ marginBottom: '15px' }}>
			
			<TextInput
				value={
					notApplied
					? 'Não se aplica'
					: value === null ? '' : value
				}
				label={attribute.name}
				setValue={(value) => setValue(value)}
				disabled={notApplied}
				sx={{width: '100%'}}
			/>

			{
				attribute.tags.required !== true &&
				<FormControlLabel
					control={<Checkbox
						checked={notApplied}
						onClick={() => {
							setChanged(true)
							setNotApplied(!notApplied)
						}}
					/>}
					label="Não se aplica"
				/>
			}

			{changed && <div style={{ display: 'flex', marginTop: '10px' }}>
				
				<Button onClick={handleClickCancel} >
					Cancelar
				</Button>

				<Button onClick={handleClickSubmit} variant='contained' sx={{ marginLeft: 'auto', order: 2 }} >
					Alterar
				</Button>

			</div>}

		</Grid>
	</Grid>
}