import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom'

import Logo from '../ComponentAuthLogo'
import { LoginLink } from '../ComponentAuthLinks'
import ComponentCopyright from '../ComponentCopyright'

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function PagePasswordRecover() {
  
  const navigate = useNavigate()

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/login')
    /*
    const data = new FormData(event.currentTarget);
    console.log({
      email: data.get('email'),
      password: data.get('password'),
    });
    */
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Logo />
          <Typography component="h1" variant="h5">
            Recuperar Senha
          </Typography>
          <Typography component="p" variant="p" sx={{ mt:2, textAlign: 'center' }}>
            Vamos te encaminhar um e-mail<br/>com o link para gerar uma nova senha.
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <TextField
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Receber Email
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <LoginLink />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <ComponentCopyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}