import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';

import MenuAppBar from './MenuAppBar'

export default function Layout(props) {

	return <React.Fragment>

    <CssBaseline />
    
    <MenuAppBar />

    {props.children}

  </React.Fragment>

}