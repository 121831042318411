import { useState, useEffect } from 'react'
import { useAuth } from '../../../../../AuthContext'

import { useGridApiContext } from '@mui/x-data-grid'

import Button from '@mui/material/Button'
import CancelIcon from '@mui/icons-material/Cancel'

import fetch_item from '../fetch_item'
import { mount_item } from '../mount_item'

function CancelMassiveButton({rows}){
	const apiRef = useGridApiContext()
	const { token } = useAuth()
	const [changed, setChanged] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => setChanged(rows.filter(row => row.changed === true)), [rows])

	const handleClick = async () => {
		setLoading(true)
		const itemsToUpdate = changed.map(item => item.id)
		const response = await fetch_item(token, itemsToUpdate.join(','))
		const mounted_items = response.items.map(item => mount_item(item))
		mounted_items.forEach(mounted_item => apiRef.current.updateRows([mounted_item]))
		setLoading(false)
	}

	return <Button
		variant='contained'
		onClick={handleClick}
		disabled={loading || changed.length <= 0}
		size='small'
		sx={{ marginBottom: '10px' }}
	>
		<CancelIcon sx={{height: '20px', marginRight: '5px'}} /> Cancelar Alterações
	</Button>
}

export default CancelMassiveButton