import React from 'react'
import ReactDOM from 'react-dom/client'

import { AuthProvider } from './AuthContext'
import { PermissionsProvider } from './PermissionsContext'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { ptBR } from '@mui/x-data-grid';
import { ptBR as coreptBR } from '@mui/material/locale';

import App from './App'

const root = ReactDOM.createRoot(document.getElementById('root'))

const token = localStorage.getItem('token')

const theme = createTheme(
  ptBR, // x-data-grid translations
  coreptBR, // core translations
)

root.render(
  <AuthProvider token={token}>
    <PermissionsProvider>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </PermissionsProvider>
  </AuthProvider>
)