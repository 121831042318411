import { useNavigate } from 'react-router-dom'

import Button from '@mui/material/Button'

export default function ConciliationMenu () {
	
	const navigate = useNavigate()

	return <>
		<Button
      onClick={() => navigate('/panel/conciliation')}
    >Conciliação</Button>
	</>

}