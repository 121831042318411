import mount_order from './mount_order'

export default function search_filter_mount (orders, search) {

	const mounted_orders = []

	orders.forEach(order => {

		const search_array = search.includes("|") ? search.split("|") : [search]
		
		function find_order_or_buyer_or_nf_or_payment (string, order) {
			
			const searchStrings = string.split(" ")
	    return searchStrings.every(searchString => {
	    	
	    	return (
	        (
	        	order.pack_id
	        	? order.pack_id.toString().includes(searchString.toLowerCase())
	        	: order.orders[0].toString().includes(searchString.toLowerCase())
	        )
	         ||
	        (
	        	order.buyer.toLowerCase().includes(searchString.toLowerCase())
	        )
	         ||
	        (
	        	order.invoice === null
	        	? false
	        	: order.invoice.number.toString().toLowerCase().includes(searchString.toLowerCase())
	        )
	      )
		    
	    })
		}

		function find_arraystrings_in_item (search_array, order) {
			return search_array.some(search_string => find_order_or_buyer_or_nf_or_payment(search_string, order))
		}

		const matched_search = find_arraystrings_in_item(search_array, order)

		if (matched_search) mounted_orders.push(mount_order(order))

	})

	return mounted_orders
}