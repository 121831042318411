import { useState, useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import Button from '@mui/material/Button'

import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../AuthContext'
import axios from '../../backend-config'

export default function HasNotIntegration ({children}) {
	
	const [loading, setLoading] = useState(true)
	const [hasIntegration, setHasIntegration] = useState(false)
	const navigate = useNavigate()
	const { token } = useAuth()

	async function fetch_integration () {
		const response = await axios.get('/user', {
			headers: { Authorization: token },
		})
		setHasIntegration(response.data.store_has_integration)
	}

	useEffect(() => {
		fetch_integration().then(() => {
			setLoading(false)
		})
	})

	return hasIntegration
	? loading === false && <>{children}</>
  : loading === false && <Grid container spacing={2} style={{padding:'20px 0'}}>
	  	<Grid lg={12} sx={{ textAlign: 'center' }}>
	    	<p>Você ainda não possui uma integração.</p>
	  		<Button
	  			onClick={() => navigate('/panel/store')}
	  			variant="contained"
	  		>
	  			Integrar ao Mercado Livre
	  		</Button>
	    </Grid>
	  </Grid>
  
}