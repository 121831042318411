import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { orange, purple } from '@mui/material/colors'

import dateConverter from './dateConverter'

import './MetricsChart.css'

export default function ItemVisitsChart({itemVisits, itemOrders}) {

  const dates = itemVisits.map(i => i.date)

  const orders = itemOrders === null
    ? dates.map(date => { return { date, sold_quantity: 0 } })
    : dates.map(date => {

      const dayOrders = itemOrders.filter(order => dateConverter(order.date_closed) === date )
      return {
        date,
        sold_quantity: dayOrders.length > 0 ? dayOrders[0].sold_quantity : 0
      }
    })

  const VisitsSerie = {
    data: itemVisits.map(i => i.visits),
    label: 'Visitas',
    id: 'visits',
    color: orange[500],
    type: 'bar',
    stack: 'total',
  }

  const OrdersSerie = {
    data: orders.map(i => i.sold_quantity),
    label: 'Unidades Vendidas',
    id: 'ord',
    color: purple[500],
    type: 'bar',
    stack: 'orders',
  }

  return (
    <BarChart
      sx={{ width: '100vw' }}
      height={400}
      series={[
        VisitsSerie,
        OrdersSerie
      ]}
      xAxis={[{
        data: dates,
        scaleType: 'band',
      }]}
    />
  )

}