import { useState, useEffect } from 'react'

import Grid from '@mui/material/Unstable_Grid2'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'

export default function StatusSelect ({ listener }) {

  const [status, setStatus] = useState('all')

  useEffect(() => {
    listener(status)
  }, [status])

  return <Grid xs={12} lg={2}>
    <FormControl fullWidth size="small">
      <InputLabel id="status-select">Status</InputLabel>
      <Select
        labelId="status-select"
        id="status-select"
        value={status}
        label="Status"
        onChange={event => setStatus(event.target.value)}
        slotProps={{ textField: { size: 'small' } }}
      >
        <MenuItem value='all'>Todos</MenuItem>
        <MenuItem value='pending'>Pendente</MenuItem>
        <MenuItem value='released'>Liberado</MenuItem>
      </Select>
    </FormControl>
  </Grid>

}