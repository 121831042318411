import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { orange, purple } from '@mui/material/colors'

import dateConverter from './dateConverter'

import './MetricsChart.css'

export default function ItemVisitsChart({itemVisits, itemOrders}) {

  const dates = itemVisits.map(i => i.date)

  const VisitsSerie = {
    data: itemVisits.map(i => i.visits),
    label: 'Visitas',
    id: 'visits',
    color: orange[500],
    type: 'bar',
    stack: 'total',
  }

  return (
    <BarChart
      sx={{ width: '100vw' }}
      height={400}
      series={[
        VisitsSerie
      ]}
      xAxis={[{
        data: dates,
        scaleType: 'band',
      }]}
    />
  )

}