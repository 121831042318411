import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import Layout from '../../../layout/panel/Layout'
import HasNotIntegration from '../HasNotIntegration'
import useDevolutions from './useDevolutions'

import useMonths from './useMonths'

export default function PageDevolutions () {

	const { getLast12Months } = useMonths()
	const months = getLast12Months()
	
	const {
		devolutions,
		List,
		setFilter,
		setStatus,
		setDateRange,
	} = useDevolutions(months[0].dateRange)

	return <Layout>
		
		<Container>
			<Box sx={{ flexGrow: 1 }}>
		    
		    <HasNotIntegration children={
					
				<Grid xs={8} xsOffset={2} container spacing={1}>

					<Grid xs={12} sx={{mt: 2}}>
						<Typography
							variant="h5"
							component="div"
							sx={{ flexGrow: 1 }}
						>
							Devoluções
						</Typography>
					</Grid>

					<Grid xs={4}>
						<label>
							Visualizar Mês:<br/>
							<select onChange={e => setDateRange(e.target.value)}>
								{months.map((month, index) => (
									<option
										key={index}
										value={month.dateRange}
									>
										{month.label}
									</option>
								))}
							</select>
						</label>
					</Grid>

					<Grid xs={4}>
						<label>
							Status<br/>
							<select onChange={e => setStatus(e.target.value)}>
								<option value=''>Todas</option>
								<option value='closed'>Concluídas</option>
								<option value='oppened'>Em Andamento</option>
							</select>
						</label>
					</Grid>

					<Grid xs={4}>
						<label>
							Reputação<br/>
							<select onChange={e => setFilter(e.target.value)}>
								<option value=''>Todas</option>
								<option value='not_affected'>Não afetou Reputação</option>
								<option value='affected'>Afetou Reputação</option>
							</select>
						</label>
					</Grid>

					<Grid xs={12}>
						{devolutions === null
							? <>Carregando Devoluções...</>
							: devolutions.length > 0
								? <List />
								: <>Nenhuma devolução encontrada.</>
						}
					</Grid>

				</Grid>

			} />

		  </Box>
		</Container>
	</Layout>

}