import MeliTipsMenu from '../MeliTipsMenu'

const titleColumn = {
  field: 'title',
  headerName: 'Título',
  width: 380,
  sortable: false,
  disableColumnMenu: true,
  renderCell: (params) => <MeliTipsMenu id={params.row.id} title={params.row.title} mine={params.row.isMine} />,
}

export default titleColumn