import { useState, useEffect } from 'react'
import { useAuth } from '../../../../AuthContext'

import Grid from '@mui/material/Unstable_Grid2'
import Tooltip from '@mui/material/Tooltip'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

import fetch_orders from './fetch_orders'
import search_filter_mount from './search_filter_mount'
import DateSelect from './DateSelect'
import Search from './Search'
import StatusSelect from './StatusSelect'
import Row from './Row'

export default function OrdersReport () {

	const { token } = useAuth()

	const [orders, setOrders] = useState([])
	const [search, setSearch] = useState('')
	const [status, setStatus] = useState('all')
	
	// Ao carregar a pagina
	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search)
		const search_url_query = searchParams.get('search')
		
		if (search_url_query)
			setSearch(search_url_query)
	}, [])

	// On load page
	useEffect(() => {
		fetch_orders(token).then(orders => setOrders(orders) )
	}, [token])

	if (orders.length > 0) {

		const mounted_orders = search_filter_mount(orders, search, status)

		return <>

			<Grid xs={12} lg={10} container spacing={3} sx={{ mt: 0, mb: 2 }}>
				{/*<DateSelect />*/}

				<Search
					value={search}
					handleSearch={setSearch}
				/>

				<StatusSelect listener={v => setStatus(v)} />
			</Grid>

			<Grid lg={12} container>
				
				<Table aria-label="collapsible table">
					<TableHead>
						<TableRow
							sx={{
								'& > .MuiTableCell-root': {
									padding: '5px',
									fontSize: '11px'
								},
							}}
						>
							<TableCell>Venda</TableCell>
							{/*<TableCell>Status</TableCell>*/}
							<TableCell>Nota Fiscal</TableCell>

							<Tooltip title='ID do Pagamento no Mercado Pago' arrow>
								<TableCell>
									<span>ID do Pagamento</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Valor total do recebimento' arrow>
								<TableCell>
									<span>Total do Recebimento</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Valor total dos descontos da venda' arrow>
								<TableCell>
									<span>Total dos Descontos</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Valor líquido da venda (recebimento - descontos)' arrow>
								<TableCell>
									<span>Pagamento Líquido</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Detalhes dos descontos de cada venda' arrow>
								<TableCell sx={{ minWidth: '170px' }}>
									<span>Detalhes dos Descontos</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Status do pagamento no Mercado Pago' arrow>
								<TableCell>
									<span>Status Pagamento</span>
								</TableCell>
							</Tooltip>

							<Tooltip title='Data de liberação do pagamento no Mercado Pago' arrow>
								<TableCell>
									<span>Liberação Pagamento</span>
								</TableCell>
							</Tooltip>

						</TableRow>
					</TableHead>
					<TableBody>
						{mounted_orders.map((order) => <Row key={order.id} order={order} />)}
					</TableBody>
				</Table>

			</Grid>
		</>

	}

	else return <p>Carregando...</p>

}