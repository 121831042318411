import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { usePermissions } from '../../PermissionsContext'

import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

export default function MarketMenu () {
	
	const [anchorEl, setAnchorEl] = useState(null)
	const navigate = useNavigate()
  const { has_permission } = usePermissions()

  const handleMenu = (event) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

	return (has_permission('access_power_search') || has_permission('access_concurrent')) && <>
		<Button
      onClick={handleMenu}
    >
      Concorrência
    </Button>
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{
		    style: {
		      width: 150,
		    },
		  }}
    >
      {has_permission('access_power_search') && <MenuItem onClick={() => navigate('/panel/simple-search')}>Busca Simples</MenuItem>}
      {has_permission('access_power_search') && <MenuItem onClick={() => navigate('/panel/power-search')}>Super Busca</MenuItem>}
      {has_permission('access_concurrent') && <MenuItem onClick={() => navigate('/panel/concurrent-search')}>Concorrente</MenuItem>}
    </Menu>
	</>

}