import { useState, useEffect, forwardRef } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import DialogContent from '@mui/material/DialogContent';

// Icons
import AssessmentIcon from '@mui/icons-material/Assessment';
import { orange } from '@mui/material/colors'

import ChartSkeleton from './ChartSkeleton'
import VisitsFetch from './VisitsFetch'
import ItemVisitsChart from './ItemVisitsChart'
import dateConverter from './dateConverter'

import {useAuth} from '../../../../../AuthContext'

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

async function fetchVisits(token, id) {
  const response = await VisitsFetch(token, id)

  const results = response.results.sort((a,b) => new Date(a.date) - new Date(b.date))

  const visitsSeries = results.map(result => {
    return {
      date: dateConverter(result.date),
      visits: result.total
    }
  })
  return visitsSeries
}

export default function MetricsDialog(props) {
  
  const [open, setOpen] = useState(false)
  const [itemVisits, setItemVisits] = useState(null)

  const {token} = useAuth()

  useEffect(() => {

    if (open) {

      async function fetchInfo () {
        const itemVisits = await fetchVisits(token, props.item.id)
  
        const map = new Map()
        itemVisits.forEach(i => map.set(i.date, i.visits))
  
        return itemVisits
      }

      fetchInfo()
      .then(info => setItemVisits(info))

    }      

  }, [open, props.item.id, token])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (

    <>
      <Tooltip
        title='Métricas do anúncio'
        variant="solid"
        placement="left"
        arrow
      >
        <AssessmentIcon
          onClick={handleClickOpen}
          sx={{ cursor: 'pointer', color: orange[500], width: '20px', position: 'relative', top: '2px' }}
        />
      </Tooltip>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative', background: orange[500] }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {props.item.title}
            </Typography>
          </Toolbar>
        </AppBar>
        
        <DialogContent>
          <Typography sx={{ ml: 4, flex: 1 }} variant="h6" component="div">
            Visitas e Unidades Vendidas do Anúncio nos últimos 30 dias
          </Typography>
          
          {
            (itemVisits === null)
            ? <ChartSkeleton />
            : <ItemVisitsChart
                itemVisits={itemVisits}
                itemOrders={props.item.orders}
              />
          }
        </DialogContent>

      </Dialog>
    </>
  )
}