import axios from '../../../../../backend-config'

export default async function submit_manufacturing_time (token, item_id, manufacturing_time) {
	
  const response = await axios.put(`mercadolivre/items`, {
    items: [
      {
        id: item_id,
        manufacturing_time: manufacturing_time,
      }
    ]
  }, {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
			Accept: 'application/json',
    },
  })
  
  return response.data

}