import { useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Alert from '@mui/material/Alert'

import { useGridApiContext } from '@mui/x-data-grid'

import Tooltip from '@mui/material/Tooltip'
import RepeatOnIcon from '@mui/icons-material/RepeatOn'
import { blue } from '@mui/material/colors'

import { useAuth } from '../../../../../AuthContext'

import submit_item_relist from './submit_item_relist'
import { mount_item } from '../mount_item'

export default function StatusChangeDialog({params}) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  const [updating, setUpdating] = useState(false)
  const [relistItemError, setRelistItemError] = useState(null)

  const {token} = useAuth()
  const apiRef = useGridApiContext()

  const handleClickRelist = async () => {
    setUpdating(true)
    setRelistItemError(null)
    
    const response = await submit_item_relist(token, params.row.id)

    if (response.item && response.item.error) {
      setRelistItemError('Houve um erro e o anúncio não pode ser recadastrado. Por favor, tente novamente.')
    } else {
      const mounted_items = mount_item(response.item[0])
      const index = apiRef.current.getRowIndexRelativeToVisibleRows(params.row.id)
      apiRef.current.unstable_replaceRows(index, [mounted_items])
      handleClose()
    }

    setUpdating(false)

  }

  return (
    <>

      <Tooltip title='Republicar anúncio'>
        <RepeatOnIcon
          sx={{
            cursor: 'pointer',
            color: updating ? blue[200] : blue[500],
            width: '21px',
            position: 'relative',
            top: '0'
          }}
          onClick={handleClickOpen}
        />
      </Tooltip>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Tem ceteza que deseja republicar o anúncio?
        </DialogTitle>
        
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Ao republicar um anúncio, você criará um novo anúncio e ele terá um ID diferente do item pai. As informações do anúncio pai (visitas, perguntas e vendas) serão passadas ao novo anúncio.
          </DialogContentText>
          {
            relistItemError &&
            <Alert severity="warning" sx={{ mt: 2 }}>
              {relistItemError}
            </Alert>
          }
        </DialogContent>
        
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={updating}
          >
            Cancelar
          </Button>

          <Button
            onClick={handleClickRelist}
            disabled={updating}
            variant='contained'
          >
            Recadastrar
          </Button>
        </DialogActions>

      </Dialog>
    </>
  )
}