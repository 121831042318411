import StatusChangeDialog from '../StatusChangeDialog'

const statusColumn = {
  field: 'status_actions',
  type: 'status_actions',
  headerName: '',
  width: 5,
  cellClassName: 'status_actions',
  disableColumnMenu: true,
  sortable: false,
  renderCell: (params) => <StatusChangeDialog params={params} />,
}

export default statusColumn